import useTranslation from "@cospex/client/hooks/useTranslation";
import { CheckCircle, LocationOn, Lock } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";

export default function PaymentStepperMobile() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          fontSize: "1.1rem",
          px: 2,
        }}
      >
        <CheckCircle sx={{ color: theme.palette.success.main }} />
        <Box
          sx={{
            flexGrow: 1,
            height: "1px",
            background: "white",
            mx: 1,
          }}
        />
        <Lock fontSize="small" sx={{ color: "white", mr: 1 }} />
        <Typography variant="body1">{t("mobile-stepper-payment")}</Typography>
        <Box
          sx={{
            flexGrow: 1,
            height: "1px",
            background: "white",
            mx: 1,
          }}
        />
        <LocationOn sx={{ color: "white" }} />
      </Stack>
    </Box>
  );
}
