import { withTransientProps } from "@cospex/client/helpers";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  SxProps,
  Theme,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

type WrapperProps = {
  children: React.ReactNode;
};
interface FloatingCardProps extends WrapperProps {
  elevation?: number;
  className?: string;
  sx?: SxProps<Theme>;
}

const FloatingCardElement = styled(Card)<{
  elevation: number;
}>`
  padding: 1.5rem;
  box-shadow: 0px ${({ elevation }) => elevation * 1.5}px
    ${({ elevation }) => `
      ${elevation * 5}px
    `}
    rgba(0, 0, 0, ${({ elevation }) => (elevation > 5 ? 0.07 : 0.1)});
`;
export function FloatingCard({
  children,
  elevation = 2,
  className,
  sx,
}: FloatingCardProps) {
  return (
    <FloatingCardElement className={className} sx={sx} elevation={elevation}>
      {children}
    </FloatingCardElement>
  );
}

const CarouselContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const CarouselItemsContainer = styled("div")`
  flex-grow: 1;
  width: 100%;
`;

const CarouselItems = styled("div")`
  display: flex;
  align-items: stretch;
  transition: transform 0.3s ease-in-out;
`;

const CarouselItemInner = styled(FloatingCard)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const CarouselItemContainer = ({
  itemsPerPage,
  children,
}: {
  itemsPerPage: number;
  children: React.ReactNode;
}) => (
  <Box
    style={{
      padding: "1rem 0.5rem",
      minWidth: 0,
      flex: `0 0 calc(100% / ${itemsPerPage})`,
    }}
  >
    {children}
  </Box>
);

const CarouselArrow = styled(IconButton, withTransientProps)<{
  $left?: boolean;
  $right?: boolean;
}>`
  ${({ theme, $left, $right }) => `
    ${$left ? "left: -50px;" : ""}
    ${$right ? "right: -50px;" : ""}
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 8px;
    margin: 0 8px;
    z-index: 2;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    ${theme.breakpoints.down("lg")} {
      ${$left ? "left: 5px;" : ""}
      ${$right ? "right: 5px;" : ""}
    }
  `}
`;

export const CarouselItem = ({ children }: { children: React.ReactNode }) => (
  <CarouselItemInner elevation={10}>{children}</CarouselItemInner>
);
const Carousel = ({ children }: { children: React.ReactNode[] }) => {
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const itemsPerPage = isDesktop ? 3 : isTablet ? 2 : 1;
  const totalItems = children.length;

  const changeItem = (direction: number) => {
    if (document.startViewTransition) {
      document.startViewTransition(() => {
        setIndex(
          (prevIndex) => (prevIndex + direction + totalItems) % totalItems
        );
      });
    } else {
      setIndex(
        (prevIndex) => (prevIndex + direction + totalItems) % totalItems
      );
    }
  };

  const visibleItems: { node: React.ReactNode; absPos: number }[] = [];

  for (let i = 0; i < itemsPerPage; i++) {
    const itemIndex = (index + i) % totalItems;
    visibleItems.push({ node: children[itemIndex], absPos: itemIndex });
  }

  return (
    <CarouselContainer>
      <CarouselArrow onClick={() => changeItem(-1)} $left>
        <ArrowBackIos sx={{ transform: "translateX(5px)" }} />
      </CarouselArrow>
      <CarouselItemsContainer>
        <CarouselItems>
          {visibleItems.map((child, idx) => (
            <CarouselItemContainer itemsPerPage={itemsPerPage} key={idx}>
              {child.node}
            </CarouselItemContainer>
          ))}
        </CarouselItems>
      </CarouselItemsContainer>
      <CarouselArrow onClick={() => changeItem(1)} $right>
        <ArrowForwardIos />
      </CarouselArrow>
    </CarouselContainer>
  );
};

export default Carousel;
