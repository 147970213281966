import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  IPhoneActivateFindMy,
  IPhoneBlockDelete,
  IPhoneFindLostOrStolen,
  IPhoneFindLostPhone,
} from "@cospex/client/tracker/components/IPhoneTutorial";
import { FloatingCard } from "@cospex/client/tracker/components/Layout";
import {
  StyledTabPanel,
  TutorialTab,
} from "@cospex/client/tracker/components/TutorialTab";

export default function TutorialIPhone() {
  const { t } = useTranslation();
  return (
    <FloatingCard sx={{ mb: 3 }}>
      <TutorialTab title={t("dashboard-tutorial-iphone-title")}>
        <StyledTabPanel value={0}>
          <IPhoneFindLostOrStolen />
        </StyledTabPanel>
        <StyledTabPanel value={1}>
          <IPhoneFindLostPhone />
        </StyledTabPanel>
        <StyledTabPanel value={2}>
          <IPhoneBlockDelete />
        </StyledTabPanel>
        <StyledTabPanel value={3}>
          <IPhoneActivateFindMy />
        </StyledTabPanel>
      </TutorialTab>
    </FloatingCard>
  );
}
