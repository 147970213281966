import IphoneImg from "@cospex/client/assets/tracker/tutorial/apple.png";
import NokiaImg from "@cospex/client/assets/tracker/tutorial/nokia.png";
import SamsungImg from "@cospex/client/assets/tracker/tutorial/samsung.png";
import WikoImg from "@cospex/client/assets/tracker/tutorial/wiko.png";
import XiaomiImg from "@cospex/client/assets/tracker/tutorial/xiaomi.png";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { FloatingCard } from "@cospex/client/tracker/components/Layout";
import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import { useEffect } from "react";

type TutorialButtonProps = {
  link: string;
  src?: string;
  alt: string;
  desc: string;
};
function TutorialButton({ link, src, alt, desc }: TutorialButtonProps) {
  return (
    <Button variant="outlined" component={Link} href={link} fullWidth>
      {src && (
        <Box
          component="img"
          sx={{
            height: 24,
            width: 24,
            marginRight: "1rem",
          }}
          alt={alt}
          src={src}
        />
      )}
      <Typography color="black">{desc}</Typography>
    </Button>
  );
}

export default function Tutorial() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <FloatingCard sx={{ mb: 3 }}>
      <>
        <Typography variant="h3" color="primary.main" mb={2}>
          {t("dashboard-tutorial-title")}
        </Typography>
        <Typography mb={2}>{t("dashboard-tutorial-step-two")}</Typography>
        <Grid spacing={2} container>
          <Grid item xs={6} md={4}>
            <TutorialButton
              link="/dashboard/tutorial/iphone"
              src={IphoneImg}
              alt="iPhone"
              desc="iPhone"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TutorialButton
              link="/dashboard/tutorial/samsung"
              src={SamsungImg}
              alt="Samsung"
              desc="Samsung"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TutorialButton
              link="/dashboard/tutorial/wiko"
              src={WikoImg}
              alt="Wiko"
              desc="WIko"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TutorialButton
              link="/dashboard/tutorial/xiaomi"
              src={XiaomiImg}
              alt="Xiaomi"
              desc="Xiaomi"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TutorialButton
              link="/dashboard/tutorial/nokia"
              src={NokiaImg}
              alt="Nokia"
              desc="Nokia"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TutorialButton
              link="/dashboard/tutorial/others"
              alt="Others"
              desc={t("dashboard-tutorial-others")}
            />
          </Grid>
        </Grid>
        <Stack sx={{ mt: "2rem", mb: "1rem", alignItems: "center" }}>
          <Button
            variant="contained"
            fullWidth={false}
            component={Link}
            href="/dashboard/sms/"
            disableElevation
            sx={{
              px: 3,
              py: 1.5,
            }}
          >
            <Typography color="white">
              {t("dashboard-tutorial-sms-button")}
            </Typography>
          </Button>
        </Stack>
      </>
    </FloatingCard>
  );
}
