import { Typography, useTheme } from "@mui/material";

export function Label({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  return (
    <Typography
      variant="body1"
      sx={{
        textTransform: "uppercase",
        color: theme.palette.primary.main,
        fontWeight: "bold",
      }}
    >
      {children}
    </Typography>
  );
}
