import { API_PATH_LOGIN } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { CenteredCardTitle } from "@cospex/client/tracker/components/CenteredCardTitle";
import { ShadowCard } from "@cospex/client/tracker/components/Layout";
import { Label } from "@cospex/client/tracker/components/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

function dummyFunctionForI18nextScanner() {
  const { t } = useTranslation();
  t("user-not-found");
  t("user-disabled");
  t("user-already-exists");
  t("invalid-form");
  t("invalid-credentials");
  t("invalid-session");
  t("invalid-payment");
  t("invalid-request");
  t("customer-not-found");
  t("customer-already-unsubscribed");
  t("unsubscribe-error");
  t("action-unauthorized");
  t("invoice-generation-error");
  t("transaction-not-found");
  t("payment-not-found");
  t("sms-not-found");
  t("customer-not-found");
}

type LoginData = {
  email: string;
  password: string;
};

const Login = () => {
  const { t } = useTranslation();

  const validationSchema = z.object({
    email: z.string().email(t("login-email-invalid")),
    password: z.string().min(4, t("login-password-min")),
  });
  const { control, handleSubmit, formState } = useForm<LoginData>({
    resolver: zodResolver(validationSchema),
  });
  const { signin } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const mutation = useMutation({
    mutationFn: ({ email, password }: LoginData) => {
      return axios.post(
        API_PATH_LOGIN,
        { email, password },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: (res, { email }) => {
      const token = res?.data?.token || "";
      const refreshToken = res?.data?.refresh_token || "";
      signin(email, token, refreshToken);
      navigate("/dashboard", { replace: true });
    },
    onError(res: any) {
      const error = res.response?.data?.error || res.message;
      setError(error);
    },
  });
  const theme = useTheme();

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        py: 8,
      }}
    >
      <ShadowCard
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: 690,
          gap: 1,
          p: {
            md: 8,
            xs: 2,
          },
        }}
      >
        <Label>{t("login-label")}</Label>
        <CenteredCardTitle text={t("login-title")} />
        <br />
        <Box
          component="form"
          onSubmit={handleSubmit((data: LoginData) => mutation.mutate(data))}
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Stack gap="1rem">
            <TextInput
              name="email"
              control={control}
              formState={formState}
              label={t("login-email")}
              fullWidth
            />
            <TextInput
              name="password"
              control={control}
              formState={formState}
              label={t("login-password")}
              type={showPassword ? "text" : "password"}
              fullWidth
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />

            <Typography variant="body2" sx={{ mb: 2 }}>
              {t("login-forgot-password")}{" "}
              <Typography
                variant="body2"
                component={Link}
                href="/reset"
                sx={{ cursor: "pointer" }}
              >
                {t("login-reset-password")}
              </Typography>
            </Typography>

            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={mutation.isLoading}
              sx={{ color: "#fff" }}
            >
              {t("login-now-button")}
            </LoadingButton>
            {error && <Alert severity="error">{t(error)}</Alert>}
          </Stack>
        </Box>
      </ShadowCard>
    </Stack>
  );
};

export default Login;
