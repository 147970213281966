import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, Typography, styled } from "@mui/material";

const UL = styled("ul")(() => ({
  padding: 0,
  margin: 0,
  counterReset: "step-counter", // Initialize the counter
}));

const LI = styled("li")(() => ({
  listStyleType: "none",
}));

const CounterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "&::before": {
    counterIncrement: "step-counter",
    content: '"#" counter(step-counter)',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#FFF",
  },
}));

const Item = ({ title, desc }: { title: string; desc?: string }) => {
  return (
    <LI>
      <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
        <CounterBox
          sx={{
            height: 46,
            width: 46,
            borderRadius: "50%",
            backgroundColor: "primary.main",
            marginRight: "1.5rem",
          }}
        />
        <Typography sx={{ fontWeight: 500, flexBasis: "80%" }}>
          {title}
        </Typography>
      </Stack>

      {desc && (
        <Stack
          sx={{ flexDirection: "row", marginLeft: "22px", marginY: "10px" }}
        >
          <Box
            sx={{
              height: 46,
              borderLeft: "2px solid",
              borderColor: "primary.main",
              marginRight: "2.7rem",
            }}
          />
          <Typography>{desc}</Typography>
        </Stack>
      )}
    </LI>
  );
};

export default function HowTo() {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" mb="4rem" px={4}>
      <Typography variant="h2">{t("how-to-title")}</Typography>
      <Typography variant="body1" sx={{ color: "primary.main", my: "2rem" }}>
        {t("how-to-desc")}
      </Typography>
      <UL
        sx={{
          width: { xs: "90%", md: "60%" },
        }}
      >
        <Item
          title={t("how-to-list-item-title-one").toString()}
          desc={t("how-to-list-item-desc-one").toString()}
        />
        <Item
          title={t("how-to-list-item-title-two").toString()}
          desc={t("how-to-list-item-desc-two").toString()}
        />
        <Item
          title={t("how-to-list-item-title-three").toString()}
          desc={t("how-to-list-item-desc-three").toString()}
        />
        <Item title={t("how-to-list-item-title-four").toString()} />
      </UL>
    </Stack>
  );
}
