import useAuth from "@cospex/client/hooks/useAuth";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function PublicOutlet() {
  const { session } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (session) {
      navigate("/dashboard");
    }
  }, [session]);
  return <Outlet />;
}
