import Counter from "@cospex/client/components/Counter";
import FeaturesList from "@cospex/client/components/FeaturesList";
import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import SearchPhone from "@cospex/client/components/SearchPhone";
import useAuth from "@cospex/client/hooks/useAuth";
import { useTitle } from "@cospex/client/hooks/useTitle";
import useTranslation from "@cospex/client/hooks/useTranslation";
import HomepageGraphic from "@cospex/client/tracker/components/HomepageGraphic";
import { HomeCard, Section } from "@cospex/client/tracker/components/Layout";
import LocateCard from "@cospex/client/tracker/components/Locate";
import Pricing from "@cospex/client/tracker/components/Pricing";
import People from "@cospex/client/tracker/components/icons/People";
import PhoneX from "@cospex/client/tracker/components/icons/PhoneX";
import Pin from "@cospex/client/tracker/components/icons/Pin";
import Thief from "@cospex/client/tracker/components/icons/Thief";
import HowTo from "@cospex/client/tracker/components/sections/HowTo";
import PhonesLocated from "@cospex/client/tracker/components/sections/PhonesLocated";
import ServiceCompatible from "@cospex/client/tracker/components/sections/ServiceCompatible";
import GlobeImage from "@cospex/client/tracker/img/globe.png";
import modalAnimationData from "@cospex/client/tracker/img/lottie_tracking_anim.json";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import Rocket from "./icons/Rocket";
import SafetyShield from "./icons/SafetyShielf";
import Signal from "./icons/Signal";

type HomeProps = {
  title: string | ReactElement;
  titleFontSize?: Partial<Record<"xs" | "sm" | "md" | "lg", string>>;
};

export default function Home({
  title,
  titleFontSize = {
    xs: "33px",
    sm: "50px",
  },
}: HomeProps) {
  const { t } = useTranslation();
  useTitle(t("home-page-title"));
  const { session } = useAuth();
  const navigate = useNavigate();

  const onTrack = () => {
    if (session?.email) {
      navigate("/dashboard");
      return;
    }
    navigate("/payment");
  };

  const theme = useTheme();
  return (
    <Box>
      <Section>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: titleFontSize,
                }}
              >
                {title}
                <Box
                  component="span"
                  sx={{ display: "block", color: "primary.main" }}
                >
                  {t("home-title-addendum")}
                </Box>
              </Typography>
              <Typography variant="h5" sx={{ mb: 3 }}>
                {t("home-description")}
              </Typography>
              <SearchPhone
                onTrack={onTrack}
                business="tracker"
                modalAnimationData={modalAnimationData}
              />
              <FeaturesList
                featureKeys={[t("home-features-1"), t("home-features-2")]}
              />
              <Counter>{t("home-numbers-identified")}</Counter>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  marginX: "auto",
                  aspectRatio: "812/864",
                  maxWidth: 400,
                }}
              >
                <HomepageGraphic />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <PhonesLocated />
      <Box bgcolor={theme.palette.primary.light}>
        <Section
          sx={{
            py: {
              xs: 4,
              md: 12,
              lg: 14,
            },
          }}
        >
          <Typography variant="h3" textAlign="center" mb={4}>
            {t("home-globe-title")}
          </Typography>
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              rowGap: "2rem",
            }}
          >
            <Box
              flex="1 1 0"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                height="auto"
                width="100%"
                maxWidth="420px"
                src={GlobeImage}
              />
            </Box>
            <Stack
              flex="1 1 0"
              sx={{ justifyContent: "center", rowGap: "1rem" }}
            >
              <HomeCard
                Icon={Rocket}
                title={t("home-globe-phones-title")}
                desc={t("home-globe-phones-desc")}
                variant="h4"
              />
              <HomeCard
                Icon={Signal}
                title={t("home-globe-operators-title")}
                desc={t("home-globe-operators-desc")}
                variant="h4"
              />
              <HomeCard
                Icon={SafetyShield}
                title={t("home-globe-applications-title")}
                desc={t("home-globe-applications-desc")}
                variant="h4"
              />
            </Stack>
          </Stack>
        </Section>
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          pt: { md: 8, xs: 4 },
          pb: 4,
          mb: {
            md: -2,
            xs: -6,
          },
        }}
      >
        <Container>
          <ReviewCarousel />
        </Container>
      </Box>
      <Section>
        <Stack alignItems="center" mt="2rem">
          <Typography variant="h2" textAlign="center">
            {t("home-locate-title")}
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-evenly",
            columnGap: "2.5em",
            rowGap: "2.5em",
            paddingTop: "3rem",
          }}
        >
          <LocateCard
            Icon={People}
            title={t("home-locate-love-title")}
            desc={t("home-locate-love-desc")}
          />
          <LocateCard
            Icon={Thief}
            title={t("home-locate-stolen-title")}
            desc={t("home-locate-stolen-desc")}
          />
          <LocateCard
            Icon={PhoneX}
            title={t("home-locate-lost-title")}
            desc={t("home-locate-lost-desc")}
          />
        </Stack>
      </Section>
      <Section
        sx={{
          backgroundColor: "primary.main",
          mb: { xs: 4, sm: 6, md: 8 },
        }}
      >
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          gap={4}
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems="center"
            gap={2}
          >
            <Pin color="white" />
            <Typography variant="h4" color="white" textAlign="center">
              {t("find-someone-title")}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            sx={{
              color: "black",
              backgroundColor: "white",
              fontSize: "1.15rem",
              py: 1.5,
              px: 3,
            }}
          >
            {t("find-my-phone")}
          </Button>
        </Stack>
      </Section>
      <HowTo />
      <Container>
        <Box>
          <Stack alignItems="center">
            <Typography variant="h2" sx={{ padding: "20px" }}>
              {t("homepage-faq-title")}
            </Typography>
          </Stack>
          <Box
            sx={{
              "> .MuiAccordion-root": {
                boxShadow: "none",
                margin: "16px 0",
              },
              "> .MuiAccordion-root:before": {
                display: "none",
              },
              ".MuiTypography-body2": {
                fontSize: "1.2rem",
              },
              borderRadius: "8px",
            }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5">
                  {t("homepage-faq-accordion-a-title")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {t("homepage-faq-accordion-a-body")}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1b-content"
                id="panel1a-header"
              >
                <Typography variant="h5">
                  {t("homepage-faq-accordion-b-title")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {t("homepage-faq-accordion-b-body")}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1a-header"
              >
                <Typography variant="h5">
                  {t("homepage-faq-accordion-c-title")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {t("homepage-faq-accordion-c-body")}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1a-header"
              >
                <Typography variant="h5">
                  {t("homepage-faq-accordion-d-title")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {t("homepage-faq-accordion-d-body")}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1e-content"
                id="panel1a-header"
              >
                <Typography variant="h5">
                  {t("homepage-faq-accordion-e-title")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  {t("homepage-faq-accordion-e-body")}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Container>
      <ServiceCompatible />
      <Section>
        <Stack alignItems="center">
          <Pricing />
        </Stack>
      </Section>
    </Box>
  );
}
