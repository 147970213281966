import { Box, Stack, Typography, styled } from "@mui/material";

export const StyledUL = styled("ul")`
  padding: 0;
  margin-top: 1.5rem;
`;

export const StyledOL = styled("ol")`
  padding: 0;
  margin-top: 1.5rem;
`;

export const StyledLI = styled("li")`
  text-decoration: none;
  list-style: none;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

type ListProps = {
  startAdornment?: JSX.Element;
  label: string;
};

export function List({ startAdornment, label }: ListProps) {
  return (
    <StyledLI>
      <Stack
        sx={{ flexDirection: "row", alignItems: "center", columnGap: ".5rem" }}
      >
        {startAdornment && startAdornment}
        <Typography>{label}</Typography>
      </Stack>
    </StyledLI>
  );
}

export function ListItem({
  label,
  iconPath,
}: {
  label: string;
  iconPath: string;
}) {
  return (
    <StyledLI>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 1 }}>
        <Typography>{label}</Typography>
        <Box component="img" width={16} height={16} src={iconPath} />
      </Stack>
    </StyledLI>
  );
}
