export default function Pin({ color }: { color: string }) {
  return (
    <svg
      width="22"
      height="32"
      viewBox="0 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0C4.91857 0 0 4.91857 0 11C0 13.7343 0.785714 16.2957 2.21571 18.6057C3.70857 21.0257 5.67286 23.1 7.18143 25.52C7.92 26.6986 8.45429 27.7986 9.02 29.0714C9.42857 29.9357 9.75857 31.4286 11 31.4286C12.2414 31.4286 12.5714 29.9357 12.9643 29.0714C13.5457 27.7986 14.0643 26.6986 14.8029 25.52C16.3114 23.1157 18.2757 21.0414 19.7686 18.6057C21.2143 16.2957 22 13.7343 22 11C22 4.91857 17.0814 0 11 0ZM11 15.3214C8.83143 15.3214 7.07143 13.5614 7.07143 11.3929C7.07143 9.22429 8.83143 7.46429 11 7.46429C13.1686 7.46429 14.9286 9.22429 14.9286 11.3929C14.9286 13.5614 13.1686 15.3214 11 15.3214Z"
        fill={color}
      />
    </svg>
  );
}
