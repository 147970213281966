import IPhoneSettingsOneEnImg from "@cospex/client/assets/tracker/tutorial/en/ios13-iphone-settings-1.png";
import IPhoneSettingsTwoEnImg from "@cospex/client/assets/tracker/tutorial/en/ios13-iphone-settings-2.png";
import IPhoneBlockDeleteEnImg from "@cospex/client/assets/tracker/tutorial/en/ios13-iphone-xr.png";
import IPhoneDirectionEnImg from "@cospex/client/assets/tracker/tutorial/en/ios13-iphone-xs-find-my-devices-directions.jpg";
import IPhoneFindMyEnImg from "@cospex/client/assets/tracker/tutorial/en/ios13-iphone-xs-find-my-devices-map.jpg";
import IPhonePlaySoundEnImg from "@cospex/client/assets/tracker/tutorial/en/ios13-iphone-xs-find-my-devices-play-sound.png";
import IPhoneLostLockedEnImg from "@cospex/client/assets/tracker/tutorial/en/ios13-iphone-xs-lost-mode.jpg";
import IPhoneSettingsOneFrImg from "@cospex/client/assets/tracker/tutorial/fr/ios13-iphone-settings-1.png";
import IPhoneSettingsTwoFrImg from "@cospex/client/assets/tracker/tutorial/fr/ios13-iphone-settings-2.png";
import IPhoneBlockDeleteFrImg from "@cospex/client/assets/tracker/tutorial/fr/ios13-iphone-xr.png";
import IPhoneDirectionFrImg from "@cospex/client/assets/tracker/tutorial/fr/ios13-iphone-xs-find-my-devices-directions.jpg";
import IPhoneFindMyFrImg from "@cospex/client/assets/tracker/tutorial/fr/ios13-iphone-xs-find-my-devices-map.jpg";
import IPhonePlaySoundFrImg from "@cospex/client/assets/tracker/tutorial/fr/ios13-iphone-xs-find-my-devices-play-sound.png";
import IPhoneLostLockedFrImg from "@cospex/client/assets/tracker/tutorial/fr/ios13-iphone-xs-lost-mode.jpg";
import IPhoneFindMyAppImg from "@cospex/client/assets/tracker/tutorial/ios13-find-my-app-topic-icon.png";
import IPhoneSettingsFindMyImg from "@cospex/client/assets/tracker/tutorial/ios13-iphone-settings-find-my.png";
import IPhoneSettingsImg from "@cospex/client/assets/tracker/tutorial/ios-settings-icon.png";
import IPhoneSettingsOneItImg from "@cospex/client/assets/tracker/tutorial/it/ios13-iphone-settings-1.png";
import IPhoneSettingsTwoItImg from "@cospex/client/assets/tracker/tutorial/it/ios13-iphone-settings-2.png";
import IPhoneBlockDeleteItImg from "@cospex/client/assets/tracker/tutorial/it/ios13-iphone-xr.png";
import IPhoneDirectionItImg from "@cospex/client/assets/tracker/tutorial/it/ios13-iphone-xs-find-my-devices-directions.jpg";
import IPhoneFindMyItImg from "@cospex/client/assets/tracker/tutorial/it/ios13-iphone-xs-find-my-devices-map.jpg";
import IPhonePlaySoundItImg from "@cospex/client/assets/tracker/tutorial/it/ios13-iphone-xs-find-my-devices-play-sound.png";
import IPhoneLostLockedItImg from "@cospex/client/assets/tracker/tutorial/it/ios13-iphone-xs-lost-mode.jpg";
import IPhoneLocationImg from "@cospex/client/assets/tracker/tutorial/location.png";
import { StyledLI, StyledOL } from "@cospex/client/components/List";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, Typography } from "@mui/material";

export function IPhoneFindLostOrStolen() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Typography my="1.5rem" variant="h5">
        {t("dashboard-tutorial-iphone-find-lost-locate")}
      </Typography>
      <br />
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Box
            component="img"
            width="180px"
            height="172px"
            src={
              i18n.language === "en"
                ? IPhoneFindMyEnImg
                : i18n.language === "fr"
                ? IPhoneFindMyFrImg
                : i18n.language === "it"
                ? IPhoneFindMyItImg
                : IPhoneFindMyEnImg
            }
          />
          <br />
          <Typography variant="h6">
            {t("dashboard-tutorial-iphone-find-lost-show-phone")}
          </Typography>
          <StyledOL>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-show-phone-one")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-show-phone-two")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-show-phone-three")}
            </StyledLI>
          </StyledOL>
        </Stack>
        <Stack>
          <Box
            component="img"
            width="180px"
            height="172px"
            src={
              i18n.language === "en"
                ? IPhoneDirectionEnImg
                : i18n.language === "fr"
                ? IPhoneDirectionFrImg
                : i18n.language === "it"
                ? IPhoneDirectionItImg
                : IPhoneDirectionEnImg
            }
          />
          <br />
          <Typography variant="h6">
            {t("dashboard-tutorial-iphone-find-lost-directions")}
          </Typography>
          <StyledOL>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-directions-one")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-directions-two")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-directions-three")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-directions-four")}
            </StyledLI>
          </StyledOL>
        </Stack>
        <Stack>
          <Box
            component="img"
            width="180px"
            height="172px"
            src={
              i18n.language === "en"
                ? IPhonePlaySoundEnImg
                : i18n.language === "fr"
                ? IPhonePlaySoundFrImg
                : i18n.language === "it"
                ? IPhonePlaySoundItImg
                : IPhonePlaySoundEnImg
            }
          />
          <br />
          <Typography variant="h6">
            {t("dashboard-tutorial-iphone-find-lost-ring")}
          </Typography>
          <StyledOL>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-ring-one")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-ring-two")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-ring-three")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-iphone-find-lost-ring-four")}
            </StyledLI>
          </StyledOL>
        </Stack>
      </Stack>
    </>
  );
}

export function IPhoneFindLostPhone() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Typography my="1.5rem" variant="h5">
        {t("dashboard-tutorial-iphone-activate-lost-mode")}
      </Typography>
      <br />
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: "2rem",
        }}
      >
        <Box
          component="img"
          width="280px"
          height="563px"
          src={
            i18n.language === "en"
              ? IPhoneLostLockedEnImg
              : i18n.language === "fr"
              ? IPhoneLostLockedFrImg
              : i18n.language === "it"
              ? IPhoneLostLockedItImg
              : IPhoneLostLockedEnImg // default image
          }
        />
        <Stack>
          <Typography>
            {t("dashboard-tutorial-iphone-activate-lost-mode-desc-one")}
          </Typography>
          <Typography>
            {t("dashboard-tutorial-iphone-activate-lost-mode-desc-two")}
          </Typography>
          <Stack flexDirection="row" gap="1rem">
            <Stack sx={{ flex: "1 1 0" }}>
              <Typography variant="h6" sx={{ mt: "2rem", mb: "1rem" }}>
                {t("dashboard-tutorial-iphone-lost-mode-direction-title")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-direction-one")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-direction-two")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-direction-three")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-direction-four")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-direction-five")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-direction-six")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-direction-seven")}
              </Typography>
            </Stack>
            <Stack sx={{ flex: "1 1 0" }}>
              <Typography variant="h6" sx={{ mt: "2rem", mb: "1rem" }}>
                {t("dashboard-tutorial-iphone-lost-mode-ring-title")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-ring-one")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-lost-mode-ring-two")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export function IPhoneBlockDelete() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Typography my="1.5rem" variant="h5">
        {t("dashboard-tutorial-iphone-block-delete")}
      </Typography>
      <br />
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: "2rem",
        }}
      >
        <Box
          component="img"
          width="280px"
          height="563px"
          src={
            i18n.language === "en"
              ? IPhoneBlockDeleteEnImg
              : i18n.language === "fr"
              ? IPhoneBlockDeleteFrImg
              : i18n.language === "it"
              ? IPhoneBlockDeleteItImg
              : IPhoneBlockDeleteEnImg
          }
        />
        <Stack>
          <Typography>
            {t("dashboard-tutorial-iphone-block-delete-desc-one")}
          </Typography>
          <Typography>
            {t("dashboard-tutorial-iphone-block-delete-desc-two")}
          </Typography>
          <Typography>
            {t("dashboard-tutorial-iphone-block-delete-desc-three")}
          </Typography>
          <Stack flexDirection="row" gap="1rem">
            <Stack sx={{ flex: "1 1 0" }}>
              <Typography variant="h6" sx={{ mt: "2rem", mb: "1rem" }}>
                {t("dashboard-tutorial-iphone-block-delete-clear-title")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-block-delete-clear-one")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-block-delete-clear-two")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-block-delete-clear-three")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-block-delete-clear-four")}
              </Typography>
            </Stack>
            <Stack sx={{ flex: "1 1 0" }}>
              <Typography variant="h6" sx={{ mt: "2rem", mb: "1rem" }}>
                {t("dashboard-tutorial-iphone-block-delete-caution-title")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-block-delete-caution-one")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-block-delete-caution-two")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-iphone-block-delete-caution-three")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export function IPhoneActivateFindMy() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Typography my="1.5rem" variant="h5">
        {t("dashboard-tutorial-iphone-find-my")}
      </Typography>
      <br />
      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
          columnGap: "2rem",
          rowGap: "4rem",
        }}
      >
        <Stack>
          <Stack flexDirection="row" gap="10px">
            <Typography variant="h6">
              {t("dashboard-tutorial-iphone-find-my-access-setup")}
            </Typography>
            <Box
              component="img"
              width="25px"
              height="25px"
              src={IPhoneSettingsImg}
            />
          </Stack>
          <br />
          <Typography>
            {t("dashboard-tutorial-iphone-find-my-access-setup-one")}
          </Typography>
          <Typography>
            {t("dashboard-tutorial-iphone-find-my-access-setup-two")}
          </Typography>
          <Box
            component="img"
            width="200px"
            height="auto"
            m="1rem"
            src={IPhoneSettingsFindMyImg}
          />
        </Stack>
        <Stack>
          <Stack flexDirection="row" gap="10px">
            <Typography variant="h6">
              {t("dashboard-tutorial-iphone-find-my-go-locate")}
            </Typography>
            <Box
              component="img"
              width="25x"
              height="25px"
              src={IPhoneFindMyAppImg}
            />
          </Stack>
          <br />
          <Typography>
            {t("dashboard-tutorial-iphone-find-my-go-locate-one")}
          </Typography>
          <Typography>
            {t("dashboard-tutorial-iphone-find-my-go-locate-two")}
          </Typography>
          <Box
            component="img"
            width="250px"
            height="auto"
            mt="1rem"
            src={
              i18n.language === "en"
                ? IPhoneSettingsTwoEnImg
                : i18n.language === "fr"
                ? IPhoneSettingsTwoFrImg
                : i18n.language === "it"
                ? IPhoneSettingsTwoItImg
                : IPhoneSettingsTwoEnImg
            }
          />
        </Stack>
        <Stack>
          <Stack flexDirection="row" gap="10px">
            <Typography variant="h6">
              {t("dashboard-tutorial-iphone-find-my-setup-location")}
            </Typography>
            <Box
              component="img"
              width="25px"
              height="25px"
              src={IPhoneLocationImg}
            />
          </Stack>
          <br />
          <Typography>
            {t("dashboard-tutorial-iphone-find-my-setup-location-one")}
          </Typography>
          <Box
            component="img"
            width="250px"
            height="auto"
            mt="1rem"
            src={
              i18n.language === "en"
                ? IPhoneSettingsOneEnImg
                : i18n.language === "fr"
                ? IPhoneSettingsOneFrImg
                : i18n.language === "it"
                ? IPhoneSettingsOneItImg
                : IPhoneSettingsOneEnImg
            }
          />
        </Stack>
      </Stack>
    </>
  );
}
