import {
  DeleteForever,
  DownloadDoneRounded,
  OpenWithRounded,
  PauseCircleFilledRounded,
  PlayArrowRounded,
} from "@mui/icons-material";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type CustomTranslationState = {
  loadCustomTranslation: () => void;
  saveCustomTranslation: () => void;
  updateTranslation: (key: string, value: string) => void;
  customTranslation: { [key: string]: string } | null;
  translationActive: boolean;
  translationMode: boolean;
  toggleTranslating: () => void;
};

const translationsPanelStyle: React.CSSProperties = {
  position: "fixed",
  top: "1em",
  left: "1em",
  overflow: "hidden",
  background: "#fff",
  border: "1px solid #ccc",
  borderRadius: "3px",
  boxShadow:
    "0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.06)",
  zIndex: 1000,
};

export const CustomTranslationContext =
  createContext<CustomTranslationState | null>(null);

export const useCustomTranslation = () => useContext(CustomTranslationContext)!;

const LOCAL_STORAGE_KEY = "customtranslation_new";

const CustomTranslationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [customTranslation, setCustomTranslation] = useState<{
    [key: string]: string;
  } | null>(null);
  const [translationMode] = useState(
    window.location.search.includes("translationsEnabled=true")
  );
  const [translationActive, settranslationActive] = useState(translationMode);

  const loadCustomTranslation = useCallback(() => {
    const translation = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (translation) setCustomTranslation(JSON.parse(translation));
  }, []);

  useEffect(() => {
    if (translationActive) loadCustomTranslation();
  }, [translationActive, loadCustomTranslation]);

  const saveCustomTranslation = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(customTranslation));
  }, [customTranslation]);

  const updateTranslation = useCallback(
    (key: string, value: string) => {
      const updated = {
        ...(customTranslation ? customTranslation : {}),
        [key]: value,
      };
      setCustomTranslation(updated);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated));
    },
    [customTranslation]
  );

  const toggleTranslating = useCallback(
    () => settranslationActive((prevState) => !prevState),
    []
  );

  const resetTranslation = useCallback(() => {
    if (confirm("Are you sure you want to reset the translation?")) {
      setCustomTranslation(null);
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, []);

  const providerState = {
    loadCustomTranslation,
    saveCustomTranslation,
    updateTranslation,
    customTranslation,
    translationActive,
    translationMode,
    toggleTranslating,
  };

  return (
    <CustomTranslationContext.Provider value={providerState}>
      {translationMode && (
        <TranslationsPanel resetTranslation={resetTranslation} />
      )}
      {children}
    </CustomTranslationContext.Provider>
  );
};

const downloadFile = (
  filename: string,
  content: {
    [key: string]: string;
  }
) => {
  const a = document.createElement("a");
  const file = new Blob([JSON.stringify(content, null, 2)], {
    type: "text/plain",
  });
  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();
};

const TranslationsPanel = ({
  resetTranslation,
}: {
  resetTranslation: () => void;
}) => {
  const { customTranslation, toggleTranslating, translationActive } =
    useCustomTranslation();
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const moveRef = useRef<HTMLDivElement>(null);

  const hasChanges = !!customTranslation;

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();

      let startX = event.clientX;
      let startY = event.clientY;

      const handleMouseMove = (event: MouseEvent) => {
        const newX = event.clientX;
        const newY = event.clientY;

        const deltaX = newX - startX;
        const deltaY = newY - startY;

        startX = newX;
        startY = newY;

        if (ref.current && moveRef.current) {
          const { offsetLeft, offsetTop, offsetWidth, offsetHeight } =
            ref.current;
          const { clientWidth, clientHeight } = document.documentElement;

          let newLeft = offsetLeft + deltaX;
          let newTop = offsetTop + deltaY;

          // Restrict movement within x boundaries
          if (newLeft < -offsetWidth / 2) newLeft = -offsetWidth / 2;
          if (newLeft > clientWidth - offsetWidth / 2)
            newLeft = clientWidth - offsetWidth / 2;

          // Restrict movement within y boundaries
          if (newTop < 0) newTop = 0;
          if (newTop > clientHeight - offsetHeight)
            newTop = clientHeight - offsetHeight;

          setPosition({ top: newTop, left: newLeft });
        }
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    moveRef.current?.addEventListener("mousedown", handleMouseDown);

    return () => {
      moveRef.current?.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const style = {
    ...translationsPanelStyle,
    ...(position || {}),
  };
  return (
    <div style={style} ref={ref}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#f5f5f5f5", p: 2, cursor: "grab " }}
        ref={moveRef}
      >
        <Typography variant="h5" sx={{ mr: 1 }}>
          Translations
        </Typography>
        <OpenWithRounded />
      </Stack>
      <Box p={2}>
        <Stack direction="row" gap={2}>
          <Button
            onClick={toggleTranslating}
            variant="contained"
            disableElevation
            color={translationActive ? "inherit" : "success"}
            startIcon={
              translationActive ? (
                <PauseCircleFilledRounded />
              ) : (
                <PlayArrowRounded />
              )
            }
          >
            {translationActive ? "Pause" : "Start"}
          </Button>
          {translationActive && (
            <Button
              startIcon={<DownloadDoneRounded />}
              disabled={!hasChanges}
              variant="contained"
              color="success"
              disableElevation
              onClick={() => {
                downloadFile(
                  "new-translation-changes.json",
                  customTranslation!
                );
              }}
            >
              Download
            </Button>
          )}
        </Stack>

        {translationActive && hasChanges && (
          <>
            <Divider sx={{ mb: 1, mt: 1 }} />
            <Stack
              sx={{
                padding: 1,
                background: "#f5f5f5",
                borderRadius: 1,
                maxWidth: 400,
              }}
              alignItems="center"
              direction="row"
              gap={1}
            >
              <Button
                color="error"
                variant="contained"
                disableElevation
                onClick={resetTranslation}
                startIcon={<DeleteForever />}
                sx={{
                  pl: 4,
                  pr: 4,
                }}
              >
                Reset
              </Button>
              <Typography variant="body2">
                Reset the translation back to English. Cannot be undone.
              </Typography>
            </Stack>
          </>
        )}
      </Box>
    </div>
  );
};

export default CustomTranslationProvider;
