import { getItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  DoneOutline,
  LocationSearching,
  Message,
  PersonSearch,
} from "@mui/icons-material";
import { Card, Container, Grid, Link, Stack, Typography } from "@mui/material";

export default function History() {
  const { t } = useTranslation();
  const onboardingNumber = getItemWithExpiry("onboardingNumber");
  return (
    <Container>
      <Stack gap="2rem">
        <Stack gap="1rem" flexDirection="row" justifyContent="center">
          <DoneOutline sx={{ fontSize: "2rem", color: "success.light" }} />
          <Stack justifyContent="center">
            <Typography variant="h5">{t("valid-payment-validated")}</Typography>
          </Stack>
        </Stack>
        <Stack gap="1rem" flexDirection="row" justifyContent="center">
          <Message sx={{ fontSize: "2rem", color: "primary.main" }} />
          <Stack justifyContent="center">
            <Typography>{t("valid-payment-step-one")}</Typography>
          </Stack>
        </Stack>
        <Stack alignItems="center" gap=".5rem">
          <Typography>{t("valid-payment-how")}</Typography>
          <Typography color="primary.dark" variant="h5">
            {onboardingNumber}
          </Typography>
        </Stack>
      </Stack>
      <Grid container spacing={5} sx={{ my: "3rem" }}>
        <Grid item xs={12} md={6}>
          <Link href="/dashboard/sms">
            <Card
              sx={{
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              <Stack
                sx={{
                  alignItems: "center",
                  rowGap: "2rem",
                  minHeight: "311px",
                  textAlign: "center",
                }}
              >
                <LocationSearching sx={{ fontSize: "4rem" }} />
                <Typography variant="h3">
                  {t("valid-payment-locate-love-title")}
                </Typography>
                <Typography>{t("valid-payment-locate-love-desc")}</Typography>
              </Stack>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={6}>
          <Link href="/dashboard/tutorial">
            <Card
              sx={{
                "&:hover": {
                  color: "primary.light",
                },
              }}
            >
              <Stack
                sx={{
                  alignItems: "center",
                  rowGap: "2rem",
                  minHeight: "311px",
                  textAlign: "center",
                }}
              >
                <PersonSearch sx={{ fontSize: "4rem" }} />
                <Typography variant="h3">
                  {t("valid-payment-locate-lost-stolen-title")}
                </Typography>
                <Typography>
                  {t("valid-payment-locate-lost-stolen-desc")}
                </Typography>
              </Stack>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}
