interface INetwork {
  color: string;
}

export default function Network({ color }: INetwork) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 120 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3316_739)">
        <rect
          x="20"
          y="15"
          width="80"
          height="80"
          rx="12"
          fill="white"
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip0_3316_721)">
          <path
            d="M50.0389 59.2953C49.8158 59.2935 49.6016 59.2078 49.4389 59.0553C46.6484 56.2492 45.082 52.4527 45.082 48.4953C45.082 44.5379 46.6484 40.7414 49.4389 37.9353C49.6016 37.7828 49.8158 37.6971 50.0389 37.6953C50.2545 37.6984 50.4608 37.7843 50.6149 37.9353C50.6948 38.0128 50.7575 38.1063 50.7988 38.2097C50.8402 38.3131 50.8593 38.4241 50.8549 38.5353C50.8589 38.643 50.8396 38.7502 50.7982 38.8497C50.7567 38.9491 50.6942 39.0384 50.6149 39.1113C48.138 41.6064 46.748 44.9796 46.748 48.4953C46.748 52.011 48.138 55.3842 50.6149 57.8793C50.694 57.9554 50.7569 58.0468 50.7999 58.1478C50.8429 58.2488 50.8651 58.3575 50.8651 58.4673C50.8651 58.5771 50.8429 58.6858 50.7999 58.7868C50.7569 58.8879 50.694 58.9792 50.6149 59.0553C50.4608 59.2063 50.2545 59.2922 50.0389 59.2953Z"
            fill={color}
          />
          <path
            d="M43.3448 56.5604C43.1816 56.56 43.0222 56.5122 42.8858 56.4227C42.7494 56.3332 42.642 56.2059 42.5768 56.0564C41.0504 52.5639 40.6164 48.6913 41.3317 44.9476C42.0471 41.2038 43.8783 37.7641 46.5848 35.0804C46.6577 35.0011 46.7469 34.9385 46.8464 34.8971C46.9458 34.8556 47.0531 34.8363 47.1608 34.8404C47.3765 34.8435 47.5827 34.9294 47.7368 35.0804C47.8892 35.2333 47.9748 35.4405 47.9748 35.6564C47.9748 35.8723 47.8892 36.0794 47.7368 36.2324C45.2561 38.6869 43.5791 41.8368 42.9277 45.2652C42.2762 48.6937 42.6811 52.2391 44.0888 55.4324C44.136 55.5339 44.1605 55.6444 44.1605 55.7564C44.1605 55.8683 44.136 55.9789 44.0888 56.0804C44.0505 56.1812 43.9926 56.2733 43.9184 56.3516C43.8443 56.4299 43.7553 56.4927 43.6568 56.5364C43.5555 56.5657 43.4493 56.5739 43.3448 56.5604Z"
            fill={color}
          />
          <path
            d="M47.1615 62.2001C47.0522 62.1996 46.9443 62.1758 46.845 62.1303C46.7456 62.0847 46.6572 62.0185 46.5855 61.9361C45.6825 61.023 44.878 60.0175 44.1855 58.9361C44.1234 58.8454 44.0803 58.7431 44.0588 58.6353C44.0373 58.5276 44.0377 58.4166 44.0601 58.309C44.0825 58.2014 44.1264 58.0994 44.1892 58.0093C44.252 57.9191 44.3323 57.8425 44.4255 57.7841C44.5643 57.7048 44.7216 57.6634 44.8815 57.6641C45.018 57.663 45.1527 57.6952 45.274 57.758C45.3953 57.8207 45.4994 57.912 45.5775 58.0241C46.2205 59.014 46.9688 59.9314 47.8095 60.7601C47.9185 60.8816 47.9909 61.0315 48.0182 61.1924C48.0456 61.3534 48.0268 61.5188 47.964 61.6695C47.9012 61.8202 47.797 61.95 47.6634 62.0439C47.5299 62.1378 47.3725 62.192 47.2095 62.2001H47.1615Z"
            fill={color}
          />
          <path
            d="M44.3035 65.0329C44.0805 65.0311 43.8663 64.9455 43.7035 64.7929C39.386 60.4686 36.9609 54.6077 36.9609 48.4969C36.9609 42.3862 39.386 36.5252 43.7035 32.2009C43.8663 32.0484 44.0805 31.9627 44.3035 31.9609C44.5192 31.964 44.7255 32.0499 44.8795 32.2009C44.9624 32.2774 45.0286 32.3701 45.0739 32.4734C45.1191 32.5767 45.1425 32.6882 45.1425 32.8009C45.1425 32.9137 45.1191 33.0252 45.0739 33.1285C45.0286 33.2318 44.9624 33.3245 44.8795 33.4009C40.8697 37.4112 38.6171 42.8499 38.6171 48.5209C38.6171 54.1919 40.8697 59.6307 44.8795 63.6409C44.9586 63.7171 45.0216 63.8084 45.0646 63.9094C45.1076 64.0105 45.1297 64.1191 45.1297 64.2289C45.1297 64.3387 45.1076 64.4474 45.0646 64.5485C45.0216 64.6495 44.9586 64.7408 44.8795 64.8169C44.7217 64.9592 44.5159 65.0364 44.3035 65.0329Z"
            fill={color}
          />
          <path
            d="M71.087 40.6004C70.9607 40.5968 70.8368 40.5647 70.7246 40.5065C70.6124 40.4483 70.5148 40.3656 70.439 40.2644C70.103 39.8564 69.743 39.4484 69.383 39.0644C69.3039 38.9883 69.241 38.897 69.198 38.7959C69.155 38.6949 69.1328 38.5862 69.1328 38.4764C69.1328 38.3666 69.155 38.258 69.198 38.1569C69.241 38.0559 69.3039 37.9646 69.383 37.8884C69.5371 37.7374 69.7433 37.6515 69.959 37.6484C70.1821 37.6502 70.3963 37.7359 70.559 37.8884C70.991 38.3204 71.375 38.7764 71.759 39.2324C71.8245 39.3628 71.8586 39.5066 71.8586 39.6524C71.8586 39.7983 71.8245 39.9421 71.759 40.0724C71.7032 40.2095 71.6112 40.3288 71.4929 40.4175C71.3745 40.5063 71.2342 40.5612 71.087 40.5764V40.6004Z"
            fill={color}
          />
          <path
            d="M69.959 59.2956C69.7433 59.2926 69.5371 59.2066 69.383 59.0556C69.3039 58.9795 69.241 58.8882 69.198 58.7871C69.155 58.6861 69.1328 58.5774 69.1328 58.4676C69.1328 58.3578 69.155 58.2492 69.198 58.1481C69.241 58.0471 69.3039 57.9558 69.383 57.8796C71.421 55.8814 72.7565 53.2765 73.1892 50.4554C73.6219 47.6342 73.1285 44.7488 71.783 42.2316C71.6761 42.0535 71.639 41.842 71.6789 41.6381C71.7188 41.4342 71.8329 41.2524 71.999 41.1276C72.1247 41.0929 72.2574 41.0929 72.383 41.1276C72.5304 41.1301 72.6745 41.1714 72.8009 41.2472C72.9273 41.323 73.0315 41.4308 73.103 41.5596C74.632 44.3819 75.2059 47.6234 74.7386 50.799C74.2713 53.9746 72.7881 56.9134 70.511 59.1756C70.345 59.2747 70.1512 59.3168 69.959 59.2956Z"
            fill={color}
          />
          <path
            d="M72.8388 62.1998C72.7259 62.1997 72.6144 62.1762 72.5111 62.1307C72.4078 62.0853 72.3151 62.0189 72.2388 61.9358C72.0903 61.776 72.0078 61.566 72.0078 61.3478C72.0078 61.1297 72.0903 60.9197 72.2388 60.7598C75.4806 57.5113 77.3013 53.1093 77.3013 48.5198C77.3013 43.9304 75.4806 39.5284 72.2388 36.2798C72.0903 36.12 72.0078 35.91 72.0078 35.6918C72.0078 35.4737 72.0903 35.2637 72.2388 35.1038C72.3151 35.0207 72.4078 34.9544 72.5111 34.9089C72.6144 34.8635 72.7259 34.84 72.8388 34.8398C72.948 34.8403 73.0559 34.8641 73.1553 34.9097C73.2546 34.9552 73.3431 35.0214 73.4148 35.1038C75.1781 36.8648 76.5771 38.9561 77.5315 41.2581C78.486 43.5602 78.9773 46.0278 78.9773 48.5198C78.9773 51.0119 78.486 53.4795 77.5315 55.7816C76.5771 58.0836 75.1781 60.1749 73.4148 61.9358C73.3431 62.0183 73.2546 62.0845 73.1553 62.13C73.0559 62.1755 72.948 62.1993 72.8388 62.1998Z"
            fill={color}
          />
          <path
            d="M75.6973 65.0324C75.4816 65.0293 75.2754 64.9434 75.1213 64.7924C75.0422 64.7162 74.9792 64.6249 74.9362 64.5239C74.8933 64.4229 74.8711 64.3142 74.8711 64.2044C74.8711 64.0946 74.8933 63.9859 74.9362 63.8849C74.9792 63.7838 75.0422 63.6925 75.1213 63.6164C79.1121 59.601 81.352 54.1696 81.352 48.5084C81.352 42.8471 79.1121 37.4158 75.1213 33.4004C75.0422 33.3242 74.9792 33.2329 74.9362 33.1319C74.8933 33.0309 74.8711 32.9222 74.8711 32.8124C74.8711 32.7026 74.8933 32.5939 74.9362 32.4929C74.9792 32.3918 75.0422 32.3005 75.1213 32.2244C75.2754 32.0734 75.4816 31.9874 75.6973 31.9844C75.9204 31.9862 76.1346 32.0719 76.2973 32.2244C80.6149 36.5487 83.0399 42.4096 83.0399 48.5204C83.0399 54.6311 80.6149 60.4921 76.2973 64.8164C76.131 64.9602 75.9172 65.0372 75.6973 65.0324Z"
            fill={color}
          />
          <path
            d="M58.8008 78.0405C58.5781 78.0405 58.3644 77.952 58.2069 77.7945C58.0493 77.6369 57.9608 77.4233 57.9608 77.2005V55.6005C56.2012 55.0947 54.6888 53.9599 53.7114 52.4118C52.7339 50.8637 52.3595 49.0103 52.6595 47.2041C52.9594 45.398 53.9127 43.7651 55.3381 42.616C56.7635 41.4669 58.5615 40.8818 60.3901 40.9719C62.2188 41.0621 63.9505 41.8212 65.256 43.1049C66.5614 44.3886 67.3495 46.1074 67.4703 47.9343C67.5912 49.7611 67.0363 51.5687 65.9114 53.0132C64.7864 54.4577 63.1697 55.4383 61.3688 55.7685C61.1575 55.7912 60.9452 55.7353 60.7725 55.6113C60.5998 55.4873 60.4789 55.304 60.4328 55.0965C60.4122 54.9892 60.4133 54.8788 60.4361 54.7719C60.4589 54.665 60.503 54.5638 60.5656 54.4742C60.6283 54.3847 60.7083 54.3087 60.801 54.2506C60.8936 54.1926 60.9969 54.1538 61.1048 54.1365C62.5358 53.873 63.8165 53.0842 64.6957 51.925C65.5749 50.7657 65.989 49.3196 65.8567 47.8707C65.7244 46.4218 65.0553 45.0746 63.9808 44.0937C62.9062 43.1127 61.5038 42.5689 60.0488 42.5689C58.5939 42.5689 57.1915 43.1127 56.1169 44.0937C55.0424 45.0746 54.3733 46.4218 54.241 47.8707C54.1087 49.3196 54.5228 50.7657 55.402 51.925C56.2812 53.0842 57.5619 53.873 58.9928 54.1365C59.1857 54.1659 59.3609 54.2654 59.4849 54.416C59.6089 54.5665 59.673 54.7576 59.6648 54.9525V76.2885H60.4328V57.9285C60.4328 57.7098 60.518 57.4997 60.6703 57.3428C60.8227 57.186 61.0303 57.0947 61.2488 57.0885C61.4716 57.0885 61.6853 57.177 61.8428 57.3345C62.0003 57.492 62.0888 57.7057 62.0888 57.9285V77.1285C62.0888 77.3513 62.0003 77.5649 61.8428 77.7225C61.6853 77.88 61.4716 77.9685 61.2488 77.9685L58.8008 78.0405Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3316_739"
          x="0"
          y="0"
          width="120"
          height="120"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3316_739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3316_739"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3316_739">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(36 31)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
