import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

const TiltPaneElement = styled("div")`
  position: relative;
  display: inline-block;
  transform-style: preserve-3d;
  transition: transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  width: 100%;
  height: 100%;
`;

const TiltPlane = ({ children }: WrapperProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [transform, setTransform] = useState("");

  const handleMouseMove = (e: any) => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;

    const maxTilt = 5;
    const tiltX = -(y / rect.height) * maxTilt;
    const tiltY = (x / rect.width) * maxTilt;

    setTransform(
      `perspective(2000px) rotateX(${tiltX}deg) rotateY(${tiltY}deg)`
    );
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const renderChildrenWithDepth = () => {
    return React.Children.map(children, (child, index) => {
      const zIndex = index + 1;
      const depth = zIndex * 50;

      const childStyle = {
        position: "absolute",
        transform: `translateZ(${depth}px)`,
        width: "100%",
        height: "100%",
        zIndex,
      };

      return <div style={childStyle as any}>{child}</div>;
    });
  };

  return (
    <TiltPaneElement ref={containerRef} style={{ transform }}>
      {renderChildrenWithDepth()}
    </TiltPaneElement>
  );
};

export default TiltPlane;
