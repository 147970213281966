export default function Rocket({ color }: { color: string }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.2051 12.1008L8.64806 8.34553C8.64806 8.34553 4.99887 6.8959 3.29899 8.19524C1.59915 9.49531 0 13.0949 0 13.0949L1.812 14.1455C1.812 14.1455 4.10545 9.70132 6.2051 12.1008Z"
        fill={color}
      />
      <path
        d="M12.8992 18.795L16.6545 16.352C16.6545 16.352 18.1041 20.0012 16.8047 21.7011C15.5047 23.4002 11.905 25.0001 11.905 25.0001L10.8552 23.1881C10.8552 23.1881 15.2994 20.8946 12.8992 18.795Z"
        fill={color}
      />
      <path
        d="M10.0924 17.7192L9.44773 17.0753C8.27584 20.121 5.5231 19.4771 5.5231 19.4771C5.5231 19.4771 4.87914 16.7243 7.9249 15.5524L7.28094 14.9085C5.58184 14.6155 3.41427 16.6655 3.06256 18.6569C2.71157 20.6482 2.65283 22.3465 2.65283 22.3465C2.65283 22.3465 4.35115 22.2885 6.34323 21.9368C8.33531 21.5851 10.3854 19.4183 10.0924 17.7192Z"
        fill={color}
      />
      <path
        d="M24.6694 0.330706C22.0112 -0.915166 15.2797 1.41486 11.5359 6.38018C8.68318 10.1621 7.15649 12.7104 7.15649 12.7104L9.42245 14.9764L10.0237 15.5776L12.2889 17.8428C12.2889 17.8428 14.8387 16.3169 18.6207 13.465C23.5844 9.72038 25.9152 2.98886 24.6694 0.330706ZM13.4097 11.5904C12.784 10.9647 12.784 9.95002 13.4097 9.32443C14.0353 8.69883 15.0493 8.69878 15.6756 9.32521C16.3013 9.95086 16.3013 10.9648 15.6756 11.5904C15.0493 12.216 14.0353 12.216 13.4097 11.5904ZM17.1657 7.83514C16.357 7.02565 16.357 5.71489 17.1657 4.90618C17.9737 4.09743 19.2852 4.09743 20.0939 4.90618C20.9027 5.71494 20.9027 7.02565 20.0939 7.83514C19.2852 8.64312 17.9737 8.64312 17.1657 7.83514Z"
        fill={color}
      />
    </svg>
  );
}
