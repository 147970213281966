import useTranslation from "@cospex/client/hooks/useTranslation";
import HomepageGraphic from "@cospex/client/tracker/components/HomepageGraphic";
import { Section } from "@cospex/client/tracker/components/Layout";
// import { ListItem, StyledUL } from "@cospex/client/tracker/components/List";
// import EEIconPath from "@cospex/client/tracker/img/icon_ee.svg";
// import O2IconPath from "@cospex/client/tracker/img/icon_o2.svg";
// import ThreeIconPath from "@cospex/client/tracker/img/icon_three.svg";
// import VirginIconPath from "@cospex/client/tracker/img/icon_virgin.svg";
// import VodaphoneIconPath from "@cospex/client/tracker/img/icon_vodaphone.svg";
import { Box, Stack, Typography } from "@mui/material";

export default function ServiceCompatible() {
  const { t } = useTranslation();
  return (
    <Section>
      <Stack
        sx={{
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Stack flex="1 1 0" rowGap="1rem">
          <Typography variant="h2">{t("home-service-compat-title")}</Typography>
          <Typography variant="h4">
            {t("homepage-service-compat-desc-title")}
          </Typography>
          <Typography>{t("homepage-service-compat-desc")}</Typography>
          <Typography variant="h4">
            {t("homepage-service-compat-desc-title-2")}
          </Typography>
          <Typography>{t("homepage-service-compat-desc-2")}</Typography>
          {/* <StyledUL>
            <ListItem
              label={t("home-service-locate-4")}
              iconPath={VodaphoneIconPath}
            />
            <ListItem
              label={t("home-service-locate-1")}
              iconPath={EEIconPath}
            />
            <ListItem
              label={t("home-service-locate-2")}
              iconPath={O2IconPath}
            />
            <ListItem
              label={t("home-service-locate-3")}
              iconPath={VirginIconPath}
            />
            <ListItem
              label={t("home-service-locate-5")}
              iconPath={ThreeIconPath}
            />
          </StyledUL> */}
        </Stack>
        <Stack
          flex="1 1 0"
          sx={{
            pt: {
              xs: 8,
              md: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              marginX: "auto",
              aspectRatio: "812/864",
              maxWidth: 400,
            }}
          >
            <HomepageGraphic />
          </Box>
        </Stack>
      </Stack>
    </Section>
  );
}
