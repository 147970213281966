import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, InputBase, Typography, styled } from "@mui/material";
import { Control, Controller, FormState } from "react-hook-form";

interface FieldProps {
  control: Control<any>;
  name: string;
  label?: string | React.ReactNode;
  formState: FormState<any>;
  onChangeCb?: (e: TextInputChangeEvent) => void;
  disableElevation?: boolean;
  [key: string]: any;
}

export const InputElement = styled(InputBase)<{ disableElevation?: boolean }>`
  ${({ theme, disableElevation }) => `
    background-color: white;
    border-radius: 8px;
    border: 1px solid #DDE1E6;
    padding: .5rem 1rem;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    ${disableElevation ? `box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);` : ""}

    > .iti {
      margin-left: -7.5px;
    }

    &:hover {
      border: 1px solid #DDE1E6;
    }
    &.Mui-focused:not(.Mui-error) {
      border: 1px solid #6a87ae;
      box-shadow: 0px 1px 1px #00000008, 0px 3px 6px #00000005,0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
    }
    &.Mui-error {
      border-color: ${theme.palette.error.main};
      box-shadow:  0 0 0 1px ${theme.palette.error.main};
      color: ${theme.palette.error.main};
      input::placeholder {
        opacity: 1;
        color: ${theme.palette.error.main};
      }
    }
  `}
`;

export default function TextInput({
  control,
  name,
  label,
  formState,
  onChangeCb,
  ...rest
}: FieldProps) {
  const { t } = useTranslation();
  const errors = formState.errors;
  const error = errors[name];
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Box>
          <InputElement
            id={name}
            {...field}
            {...rest}
            onChange={(e) => {
              field.onChange(e);
              onChangeCb?.(e);
            }}
            placeholder={typeof label === "string" ? label : undefined}
            error={!!error}
          />
          {error && (
            <Typography sx={{ mt: 1 }} color="error">
              {t(error.message as string)}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}
