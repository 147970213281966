import { Label } from "@cospex/client/tracker/components/Typography";
import {
  Box,
  Card,
  Container,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyVariant,
  styled,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";

const halfFilledBg = (color: string) => {
  return `background: linear-gradient(0deg, ${color}, 50%, ${color}, 50%, white, 100%, white)`;
};

type WrapperProps = {
  children: React.ReactNode;
};

interface SectionProps {
  filled?: boolean;
  noPaddingBottom?: boolean;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

interface SectionElementProps {
  filled: SectionProps["filled"];
  noPaddingBottom: SectionProps["noPaddingBottom"];
}

interface FloatingCardProps extends WrapperProps {
  elevation?: number;
  className?: string;
  sx?: SxProps<Theme>;
}

const SectionElement = styled("section")<SectionElementProps>`
  ${({ theme, filled, noPaddingBottom }) => `
    overflow: hidden;
    padding: 3.5rem 0 ${noPaddingBottom ? 0 : "3.5rem"};
		${filled && halfFilledBg(theme.palette.grey[100])};
    ${theme.breakpoints.down("md")} {
      padding: 2.5rem 0 ${noPaddingBottom ? 0 : "2.5rem"};
    }
    ${theme.breakpoints.down("sm")} {
      padding: 2rem 0 ${noPaddingBottom ? 0 : "2rem"};
    }
	`}
`;

const CardStepLabelElement = styled("div")`
  ${({ theme }) => `
		width: 3rem;
		height: 3rem;
		display: inline-flex;
		font-size: 1.5rem;
		font-weight: 600;
		align-items: center;
		justify-content: center;
		background-color: ${theme.palette.grey[100]};
		margin-bottom: 1rem;
	`}
`;

const FloatingCardElement = styled(Card)<{
  elevation: number;
}>`
  padding: 1.5rem;
  box-shadow: 0px ${({ elevation }) => elevation * 1.5}px
    ${({ elevation }) => `
    ${elevation * 5}px
  `}
    rgba(0, 0, 0, ${({ elevation }) => (elevation > 5 ? 0.07 : 0.1)});
`;

export function Section({
  sx,
  filled,
  noPaddingBottom,
  children,
}: SectionProps) {
  return (
    <SectionElement {...{ filled, noPaddingBottom }} sx={sx}>
      <Container>{children}</Container>
    </SectionElement>
  );
}

export function SectionSubtitle({ children }: WrapperProps) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Label>{children}</Label>
    </Box>
  );
}

export function SectionTitle({ children }: WrapperProps) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h2" sx={{ mb: 5 }}>
        {children}
      </Typography>
    </Box>
  );
}

export function CardStepLabel({ children }: WrapperProps) {
  return <CardStepLabelElement>{children}</CardStepLabelElement>;
}

export function ParcelLocatorContainer({ children }: WrapperProps) {
  return <Box sx={{ maxWidth: 450, width: "100%" }}>{children}</Box>;
}

export function CarouselLogo({ src }: { src: string }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: ".5rem",
        p: 2,
        backgroundColor: theme.palette.grey[100],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <img src={src} alt="logo" style={{ maxHeight: 36 }} />
    </Box>
  );
}

export function Info({ children }: WrapperProps) {
  return (
    <Box sx={{ padding: "0 5%", margin: "5% auto", maxWidth: "1000px" }}>
      {children}
    </Box>
  );
}

export function FloatingCard({
  children,
  elevation = 2,
  className,
  sx,
}: FloatingCardProps) {
  return (
    <FloatingCardElement className={className} sx={sx} elevation={elevation}>
      {children}
    </FloatingCardElement>
  );
}

export function ShadowCard({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        boxShadow: `0px 5px 6px ${theme.palette.primary.main}40, 0px 4px 0px ${theme.palette.primary.main}`,
        padding: "1.5rem",
        maxWidth: "914px",
        width: "100%",
        borderRadius: "12px",
        position: "relative",
        overflow: "hidden",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}

export function HomeCard({
  Icon,
  title,
  desc,
  variant,
}: {
  Icon: React.FC<{ color: string }>;
  title: string;
  desc: string;
  variant?: TypographyVariant;
}) {
  const theme = useTheme();
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center" }} gap={3}>
      <Box>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 50,
            height: 50,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.2)",
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: `color-mix(in srgb, ${theme.palette.primary.main} 40%, white)`,
          }}
        >
          <Icon color={theme.palette.primary.main} />
        </Stack>
      </Box>
      <Stack>
        <Typography variant={variant}>{title}</Typography>
        <Typography>{desc}</Typography>
      </Stack>
    </Stack>
  );
}
