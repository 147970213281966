import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Rating, Stack, Typography } from "@mui/material";

import ReviewImage1 from "../assets/review_pp_1.webp";
import ReviewImage2 from "../assets/review_pp_2.webp";
import ReviewImage3 from "../assets/review_pp_3.webp";
import ReviewImage4 from "../assets/review_pp_4.webp";
import ReviewImage5 from "../assets/review_pp_5.webp";
import Carousel, { CarouselItem } from "./Carousel";

function Review({
  titleKey,
  descriptionKey,
  nameKey,
  imgSrc,
  rating,
}: {
  titleKey: string;
  descriptionKey: string;
  nameKey: string;
  imgSrc: string;
  rating: number;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <Typography variant="h4" gutterBottom>
          {t(titleKey)}
        </Typography>
        <Typography variant="body2" mb={2}>
          {t(descriptionKey)}
        </Typography>
      </Box>
      <Stack direction="row" alignItems="center" gap={2}>
        <Box component="img" width="48px" height="48px" src={imgSrc} />
        <Box>
          <Typography variant="h6">{t(nameKey)}</Typography>
          <Rating name="read-only" value={rating} readOnly />
        </Box>
      </Stack>
    </>
  );
}

export default function ReviewCarousel() {
  return (
    <Carousel>
      <CarouselItem>
        <Review
          nameKey="review-carousel-item-1-name"
          imgSrc={ReviewImage1}
          rating={5}
          descriptionKey="review-carousel-item-1-description"
          titleKey="review-carousel-item-1-title"
        />
      </CarouselItem>
      <CarouselItem>
        <Review
          nameKey="review-carousel-item-2-name"
          imgSrc={ReviewImage2}
          rating={5}
          descriptionKey="review-carousel-item-2-description"
          titleKey="review-carousel-item-2-title"
        />
      </CarouselItem>
      <CarouselItem>
        <Review
          nameKey="review-carousel-item-3-name"
          imgSrc={ReviewImage4}
          rating={4}
          descriptionKey="review-carousel-item-3-description"
          titleKey="review-carousel-item-3-title"
        />
      </CarouselItem>
      <CarouselItem>
        <Review
          nameKey="review-carousel-item-4-name"
          imgSrc={ReviewImage3}
          rating={5}
          descriptionKey="review-carousel-item-4-description"
          titleKey="review-carousel-item-4-title"
        />
      </CarouselItem>
      <CarouselItem>
        <Review
          nameKey="review-carousel-item-5-name"
          imgSrc={ReviewImage5}
          rating={5}
          descriptionKey="review-carousel-item-5-description"
          titleKey="review-carousel-item-5-title"
        />
      </CarouselItem>
    </Carousel>
  );
}
