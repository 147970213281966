import useTranslation from "@cospex/client/hooks/useTranslation";
import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/Tab";
import { TabPanel } from "@mui/base/TabPanel";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { Box, Button, Link, Stack, Typography, styled } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { ReactNode } from "react";

type TutorialTabProps = {
  title: string;
  children: ReactNode;
};
export function TutorialTab({ title, children }: TutorialTabProps) {
  const { t } = useTranslation();
  return (
    <>
      <Stack alignItems="center" mb="2rem">
        <Typography variant="h3">{title}</Typography>
      </Stack>
      <Box sx={{ width: "100%" }}>
        <Tabs
          defaultValue={0}
          sx={{
            display: "flex",
          }}
        >
          <StyledTabsList>
            <StyledTab value={0}>
              <Typography>{t("dashboard-tutorial-find-stolen")}</Typography>
            </StyledTab>
            <StyledTab value={1}>
              <Typography>{t("dashboard-tutorial-find-lost")}</Typography>
            </StyledTab>
            <StyledTab value={2}>
              <Typography>{t("dashboard-tutorial-find-block")}</Typography>
            </StyledTab>
            <StyledTab value={3}>
              <Typography>{t("dashboard-tutorial-find-activate")}</Typography>
            </StyledTab>
          </StyledTabsList>
          <Typography>{t("dashboard-tutorial-find-lost-desc-one")}</Typography>
          <Typography>{t("dashboard-tutorial-find-lost-desc-two")}</Typography>
          {children}
        </Tabs>

        <Stack alignItems="center">
          <Button
            variant="contained"
            component={Link}
            href="/dashboard/tutorial"
            sx={{ color: "#fff" }}
          >
            <Typography>{t("dashboard-tutorial-other-phone")}</Typography>
          </Button>
        </Stack>
      </Box>
    </>
  );
}

const StyledTab = styled(Tab)(
  ({ theme }) => `
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 224px;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
  background-color: ${theme.palette.primary.main};
  font-family: inherit;

  &:hover {
  background-color: ${theme.palette.primary.light};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
);

export const StyledTabPanel = styled(TabPanel)(
  ({ theme }) => `
  width: 100%;
  font-family: inherit;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  `
);

const StyledTabsList = styled(TabsList)(
  ({}) => `
  border-radius: 12px;
  margin: 0 auto 32px;
  display: flex;
  max-width: 600px;
  width: 100%;
  flex-wrap: wrap;
  > * {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  `
);
