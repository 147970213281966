export default function People({ color }: { color: string }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 19.7917C0 16.1729 2.30668 13.0928 5.52994 11.9417C6.406 11.6287 7.34976 11.4583 8.33333 11.4583C9.3169 11.4583 10.2607 11.6287 11.1367 11.9417C11.6126 12.1115 12.0685 12.3236 12.5 12.5732C12.9315 12.3236 13.3874 12.1115 13.8633 11.9417C14.7393 11.6287 15.6831 11.4583 16.6667 11.4583C17.6503 11.4583 18.594 11.6287 19.47 11.9417C22.6933 13.0928 25 16.1729 25 19.7917V23.9583H17.7083V21.875H22.9167V19.7917C22.9167 16.3399 20.1185 13.5417 16.6667 13.5417C15.8374 13.5417 15.0458 13.7032 14.3217 13.9965C15.7733 15.4963 16.6667 17.5396 16.6667 19.7917V23.9583H0V19.7917ZM8.33333 10.4167C7.37644 10.4167 6.47983 10.1586 5.70928 9.70833C4.16347 8.805 3.125 7.12793 3.125 5.20833C3.125 2.33185 5.45685 0 8.33333 0C10.0371 0 11.5497 0.818069 12.5 2.08282C13.4503 0.818069 14.9629 0 16.6667 0C19.5432 0 21.875 2.33185 21.875 5.20833C21.875 7.12793 20.8365 8.805 19.2907 9.70833C18.5201 10.1586 17.6236 10.4167 16.6667 10.4167C15.7097 10.4167 14.8132 10.1586 14.0426 9.70833C13.4411 9.35681 12.9165 8.88819 12.5 8.33383C12.0835 8.88833 11.5589 9.35681 10.9574 9.70833C10.1868 10.1586 9.29022 10.4167 8.33333 10.4167ZM14.5833 19.7917V21.875H2.08333V19.7917C2.08333 16.3399 4.88156 13.5417 8.33333 13.5417C11.7851 13.5417 14.5833 16.3399 14.5833 19.7917ZM11.4583 5.20833C11.4583 6.93422 10.0592 8.33333 8.33333 8.33333C6.60744 8.33333 5.20833 6.93422 5.20833 5.20833C5.20833 3.48244 6.60744 2.08333 8.33333 2.08333C10.0592 2.08333 11.4583 3.48244 11.4583 5.20833ZM16.6667 2.08333C14.9408 2.08333 13.5417 3.48244 13.5417 5.20833C13.5417 6.93422 14.9408 8.33333 16.6667 8.33333C18.3925 8.33333 19.7917 6.93422 19.7917 5.20833C19.7917 3.48244 18.3925 2.08333 16.6667 2.08333Z"
        fill={color}
      />
    </svg>
  );
}
