import useTranslation from "@cospex/client/hooks/useTranslation";
import PhoneIcon from "@mui/icons-material/Phone";
import { Stack } from "@mui/material";
import { red } from "@mui/material/colors";
import { useEffect, useState } from "react";

const ContactFormNumber = () => {
  const { i18n, t } = useTranslation();
  const [hotlineNumber, setHotlineNumber] = useState<string | null>(null);

  useEffect(() => {
    fetch("/api/hotlines")
      .then((res) => res.json())
      .then((data) => setHotlineNumber(data[i18n.language] || false));
  }, [i18n.language]);

  return (
    <Stack
      justifyContent="center"
      sx={{
        opacity: hotlineNumber === null ? 0 : 1,
        minWidth: "280px",
        transition: "all 0.3s ease",
        mb: 2,
        pb: 2,
        borderBottom: "2px solid #f2f2f2",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: 22,
        flexDirection: "row",
        gap: 1,
      }}
    >
      {hotlineNumber ? (
        <>
          <PhoneIcon sx={{ color: red[500] }} />
          {hotlineNumber}
        </>
      ) : (
        t("contact-message-us")
      )}
    </Stack>
  );
};

export default ContactFormNumber;
