import Pricing from "@cospex/client/tracker/components/Pricing";
import { Stack } from "@mui/material";

export default function Page() {
  return (
    <Stack
      sx={{
        minHeight: "80vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Pricing />
    </Stack>
  );
}
