export function getRandomValueScaledToDaytime(): number {
  const minValue = 1000;
  const maxValue = 8000;
  const range = maxValue - minValue;

  const currentHour = new Date().getHours();
  const maxHour = 23;
  const timeFactor = currentHour / maxHour;

  const scaledValue = Math.floor(minValue + range * timeFactor);
  const randomOffset = Math.floor(Math.random() * (range / 24));

  return Math.min(Math.max(scaledValue + randomOffset, minValue), maxValue);
}
