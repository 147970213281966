import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

export default function LocateCard({
  title,
  desc,
  Icon,
}: {
  Icon: React.FC<{ color: string }>;
  title: string;
  desc: string;
}) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        boxShadow: `0px 10px 13px rgba(118, 145, 255, 0.4), 0px 8px 0px ${theme.palette.primary.main}`,
        borderRadius: "20px",
        alignItems: "center",
        flex: "1 1 0",
        padding: "1.5rem",
        rowGap: "1rem",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          width: 70,
        }}
      >
        <Icon color={theme.palette.primary.main} />
      </Box>
      <Typography variant="h4">{title}</Typography>
      <Typography>{desc}</Typography>
    </Stack>
  );
}
