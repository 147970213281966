export default function Thief({ color }: { color: string }) {
  return (
    <svg
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4998 1.0282e-08C14.8793 -9.64869e-05 17.2095 0.679035 19.2164 1.95758C21.2233 3.23613 22.8236 5.06097 23.8291 7.21764C24.8347 9.37431 25.2037 11.7732 24.8929 14.1324C24.5821 16.4916 23.6043 18.713 22.0745 20.5357C23.8176 21.6884 24.8981 23.1606 24.9925 24.7703L24.9995 24.9995L12.4998 27.7772L1.38504e-05 24.9995L0.00695831 24.7703C0.100012 23.1592 1.18193 21.687 2.92356 20.5343C1.03124 18.2851 -0.00436832 15.439 1.38504e-05 12.4997C1.38504e-05 9.1846 1.31695 6.00524 3.6611 3.66109C6.00526 1.31693 9.18461 1.0282e-08 12.4998 1.0282e-08ZM12.4998 2.77772C9.92131 2.77772 7.44848 3.802 5.62525 5.62523C3.80201 7.44846 2.77773 9.9213 2.77773 12.4997C2.77773 14.6761 3.49161 16.7358 4.78464 18.4177L5.0513 18.7496L7.05542 21.1343L4.33048 22.9384L4.1402 23.0731L12.4998 24.9314L20.8579 23.0731L20.7899 23.0231L20.5426 22.8523L17.9441 21.1343L19.9482 18.7482C21.4208 16.9996 22.2263 14.7858 22.2218 12.4997C22.2218 9.9213 21.1975 7.44846 19.3743 5.62523C17.551 3.802 15.0782 2.77772 12.4998 2.77772ZM12.4998 15.2775C14.4164 15.2775 15.9719 16.2108 15.9719 17.3607C15.9719 18.5107 14.4164 19.444 12.4998 19.444C10.5831 19.444 9.0276 18.5107 9.0276 17.3607C9.0276 16.2108 10.5831 15.2775 12.4998 15.2775ZM8.33317 8.33316C9.86786 8.33316 11.1109 9.26647 11.1109 10.4164C11.1109 11.5664 9.86786 12.4997 8.33317 12.4997C6.79848 12.4997 5.55545 11.5664 5.55545 10.4164C5.55545 9.26647 6.79848 8.33316 8.33317 8.33316ZM16.6663 8.33316C18.201 8.33316 19.444 9.26647 19.444 10.4164C19.444 11.5664 18.201 12.4997 16.6663 12.4997C15.1316 12.4997 13.8886 11.5664 13.8886 10.4164C13.8886 9.26647 15.1316 8.33316 16.6663 8.33316Z"
        fill={color}
      />
    </svg>
  );
}
