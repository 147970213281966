import RequestLocation from "@cospex/client/components/RequestLocation";
import { useParams } from "react-router-dom";
import { redirect } from "react-router-dom";

export default function RequestLocationPage() {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    return redirect("/");
  }
  return <RequestLocation id={id} />;
}
