import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";

type IFeaturesList = {
  featureKeys: string[];
  sx?: SxProps<Theme> | undefined;
};

export default function FeaturesList({ featureKeys, sx }: IFeaturesList) {
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Stack
        sx={{
          gap: 2,
          flexDirection: "column",
          ...sx,
        }}
      >
        {featureKeys.map((feature, index) => (
          <Stack key={index} direction="row" gap={1}>
            <TaskAltIcon color="primary" />
            <Typography fontSize={14} variant="body1">
              {feature}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
