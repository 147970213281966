import useAuth from "@cospex/client/hooks/useAuth";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function AuthOutlet() {
  const { session } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!session) {
      const redirectTo = encodeURIComponent(
        location.pathname + location.search
      );
      navigate(`/login?redirect=${redirectTo}`);
    }
  }, [session, location, navigate]);

  return <Outlet />;
}
