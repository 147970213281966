import { getItemWithExpiry, setItemWithExpiry } from "@cospex/client/helpers";

const urlParams = new URLSearchParams(window.location.search);
const urlAbtestVersion = urlParams.get("abtest_version");

const abtestVersion = urlAbtestVersion || getItemWithExpiry("abtest_version");

if (urlAbtestVersion) {
  setItemWithExpiry("abtest_version", urlAbtestVersion);
}

export const ABTEST_VERSION = abtestVersion;
