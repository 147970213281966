const handleResponse = (response) => {
  if (response) {
    const TDSTools = new TDSWebSDK();
    const container = threeds.container;

    if (response.error) {
      document.location.href = threeds.endpoints.error;
    } else {
      if (response.transaction) {
        if (response.transaction.redirect_url_type) {
          const type = response.transaction.redirect_url_type;
          let acsUrl = response.transaction.redirect_url;
          const data = response.transaction.redirect_data;

          if (type === "3ds_method") {
            // 3ds method received, initiate the method.
            TDSTools.createIframeAndInit3DSMethod(
              acsUrl,
              data,
              "threeDSMethodIFrame",
              container,
              null
            );
            if (!axepta) {
              threeds.methodContinue();
            }
          } else if (type === "3ds_challenge") {
            // 3ds challenge received, initiate the challenge.
            TDSTools.createIframeAndInit3DSChallengeRequest(
              acsUrl,
              data,
              "02",
              "threeDSCreqIFrame",
              container,
              threeds.onChallengeLoad
            );
          } else if (
            type === "3ds1_pareq" ||
            type === "3ds_v1_payer_authentication"
          ) {
            TDSTools.createIframeAndInit3DS1PaReq(
              acsUrl,
              data,
              threeds.endpoints.notification,
              "threeDS1PaReq",
              container,
              threeds.onChallengeLoad
            );
          }
        } else if (
          response.transaction.response &&
          response.transaction.response === "A"
        ) {
          document.location.href = threeds.endpoints.receipt;
        } else {
          document.location.href = threeds.endpoints.error;
        }
      } else if (axepta) {
        let transaction = JSON.parse(atob(response));

        let acsChallengeMandated = transaction.acsChallengeMandated ?? false;
        let base64EncodedChallengeRequest =
          transaction.base64EncodedChallengeRequest ?? null;
        let acsURL = transaction.acsURL ?? null;
        let challengeWindowSize = transaction.challengeWindowSize ?? null;

        let MID = transaction.MID ?? null;
        let Len = transaction.Len ?? null;
        let Data = transaction.Data ?? null;

        if (acsChallengeMandated) {
          TDSTools.createIframeAndInit3DSChallengeRequest(
            acsURL,
            base64EncodedChallengeRequest,
            challengeWindowSize,
            "threeDSCreqIFrame",
            container,
            threeds.onChallengeLoad
          );
        } else if (MID && Len && Data) {
          document.getElementById("loading_screen").className =
            "loading-screen";
          threeds.endpoints.authentication += "/" + Data;
          threeds.methodContinue();
        }
      }
    }
  } else {
    console.log("no response");
  }
};

const receiveMessage = (event) => {
  if (event.origin !== window.location.origin) {
    console.log("receiveMessage different origin");
    console.log(event.origin);
    console.log(window.location.origin);
    // return;
  }
  threeds.handleResponse(event.data);
};

const methodContinue = () => {
  let xhr = new XMLHttpRequest();
  xhr.onload = function () {
    try {
      const response = JSON.parse(xhr.responseText);

      if (xhr.status === 200 && !response.errors) {
        threeds.handleResponse(response);
      } else {
        document.location.href = threeds.endpoints.error;
      }
    } catch (e) {
      console.log("methodContinue exception: " + xhr.responseURL);
      document.location.href = threeds.endpoints.error;
    }
  };
  xhr.open("POST", threeds.endpoints.authentication);
  xhr.send();
};

const setEndpoints = (error, authentication, receipt, notification) => {
  threeds.endpoints.error = error;
  threeds.endpoints.authentication = authentication;
  threeds.endpoints.receipt = receipt;
  threeds.endpoints.notification = notification;
};

const bind = (container = document.body) => {
  threeds.container = container;
};

const setChallengeLoad = (callable) => {
  threeds.onChallengeLoad = callable;
};

const threeds = {};
threeds.onChallengeLoad = null;
threeds.endpoints = {};
threeds.setChallengeLoad = setChallengeLoad;
threeds.handleResponse = handleResponse;
threeds.receiveMessage = receiveMessage;
threeds.methodContinue = methodContinue;
threeds.setEndpoints = setEndpoints;
threeds.bind = bind;

window.addEventListener("message", threeds.receiveMessage, false);

window.threeds = threeds;
