function TDSWebSDK() {
    /**
     * Returns the EMVCo-specified  window sizes depending on the challengeWindowSize value.
     *
     * @param challengeWindowSize - EMVCo-specified window size
     * @returns {string[]} - window size
     */
    this.getWindowSize = function (challengeWindowSize) {
        switch (challengeWindowSize) {
            case '01':
                return ['250px', '400px'];
            case '02':
                return ['350px', '480px'];
            case '03':
                return ['500px', '600px'];
            case '04':
                return ['600px', '400px'];
            case '05':
                return ['100%', '100%'];
            default:
                throw Error('Selected window size ' + challengeWindowSize + 'is not supported');
        }
    };

    /**
     * Creates a form element with one input field and sets the input value.
     *
     * @param formName - the name of the form element
     * @param formAction - the endpoint where the form will be submitted
     * @param formTarget - the iFrame name where the form will be appended to
     * @param inputName - the name of the field
     * @param inputValue - the value of the field
     * @throws {Error} - throws an error if there is a validation error
     * @returns {HTMLFormElement} - the generated form element
     */
    this.createForm = function (formName, formAction, formTarget, inputName, inputValue) {

        if (!formName || !formAction || !formTarget || !inputName || !inputValue) {
            throw Error('All fields must be present');
        }

        const form = document.createElement('form');
        form.name = formName;
        form.action = formAction;
        form.method = 'POST';
        form.target = formTarget;
        try {
            form.enctype = "application/x-www-form-urlencoded;charset=UTF-8";
        } catch (e) {
            //do nothing, some browsers do not support the above but we should continue with the default
        }

        const input = document.createElement('input');
        input.name = inputName;
        input.value = inputValue;
        form.appendChild(input);

        form.style.display = 'none';

        return form;
    };
    /**
     * Creates a form element with one input field and sets the input value.
     *
     * @param formName - the name of the form element
     * @param formAction - the endpoint where the form will be submitted
     * @param formTarget - the iFrame name where the form will be appended to
     * @param paReq - base64url encoded value
     * @param termUrl - where to send notification
     * @throws {Error} - throws an error if there is a validation error
     * @returns {HTMLFormElement} - the generated form element
     */
    this.createPaReqForm = function (formName, formAction, formTarget, paReq, termUrl) {

        if (!formName || !formAction || !formTarget || !paReq || !termUrl) {
            throw Error('All fields must be present');
        }

        const form = document.createElement('form');
        form.name = formName;
        form.action = formAction;
        form.method = 'POST';
        form.target = formTarget;
        try {
            form.enctype = "application/x-www-form-urlencoded;charset=UTF-8";
        } catch (e) {
            //do nothing, some browsers do not support the above but we should continue with the default
        }
        const paReqInput = document.createElement('input');
        paReqInput.name = 'PaReq';
        paReqInput.value = paReq;
        form.appendChild(paReqInput);

        const termUrlInput = document.createElement('input');
        termUrlInput.name = 'TermUrl';
        termUrlInput.value = termUrl;
        form.appendChild(termUrlInput);

        const mdInput = document.createElement('input');
        mdInput.name = 'MD';
        mdInput.value = '';
        form.appendChild(mdInput);

        form.style.display = 'none';
        return form;
    };
    /**
     * Creates an iframe component and attaches it to the provided container.
     *
     * @param container - HTML element to attach the iframe
     * @param name - name of the iframe container. It will be used when attaching the form element
     * @param id - id of the iframe container
     * @param width - width of the container. Default is 0.
     * @param height - height of the container. Default is 0.
     * @param onLoadCallback - callback that will be executed when the frame loads. This is optional
     * @returns {HTMLIFrameElement} generated iframe
     */
    this.createIframe = function (container, name, id, width, height, onLoadCallback) {
        width = width || '0';
        height = height || '0';

        if (!container || !name || !id) {
            throw Error('Not all required fields have value');
        }
        if (!(container instanceof HTMLElement)) {
            throw Error('Container must be a HTML element');
        }

        const strWidth = width === '100%' ? width : parseInt(width) + 'px';

        const iframe = document.createElement('iframe');
        iframe.width = width;
        iframe.height = height;
        iframe.name = name;
        iframe.setAttribute('id', id);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('border', '0');
        iframe.setAttribute('style', 'overflow:hidden;position:absolute;width:' + strWidth + ';margin:0 auto auto;');

        if (onLoadCallback && typeof onLoadCallback === 'function') {
            if (iframe.attachEvent) {
                iframe.attachEvent('onload', onLoadCallback);
            } else {
                iframe.onload = onLoadCallback;
            }
        }

        container.appendChild(iframe);

        return iframe;
    };
    /**
     * Initiates a 3DS Method request and submits the form the the 3DS Method URL. It will automatically hide the container
     * when initiating a 3DS Method request.
     *
     * @param threeDSMethodUrl - a FQDN endpoint to submit the 3DS Method request
     * @param threeDSMethodData - Base64-encoded 3DS Method Data value.
     * @param container - the iframe container where the form will be attached to. The container must have the 'name'
     *                    attribute set
     * @throws {Error} - throws error if there is a validation error
     * @returns {HTMLIFrameElement} - the container
     */
    this.init3DSMethod = function (threeDSMethodUrl, threeDSMethodData, container) {

        if (!threeDSMethodUrl || !threeDSMethodData || !container) {
            throw Error('Not all fields have value');
        }
        if (!(container instanceof HTMLIFrameElement)) {
            throw Error('Container is not an iFrame element');
        }
        if (!container.name) {
            throw Error('Container must have a name attribute');
        }

        const html = document.createElement('html');
        const body = document.createElement('body');
        const form = this.createForm('threeDSMethodForm', threeDSMethodUrl, container.name, "threeDSMethodData", threeDSMethodData);

        body.appendChild(form);
        html.appendChild(body);
        container.appendChild(html);
        container.style.display = 'none';

        form.submit();

        return container;
    };

    this.init3DS1PaReq = function (acsUrl, paReq, termUrl, container) {
        if (!acsUrl || !paReq || !termUrl || !container) {
            throw Error('Not all fields have value');
        }
        if (!(container instanceof HTMLIFrameElement)) {
            throw Error('Container is not an iFrame element');
        }
        if (!container.name) {
            throw Error('Container must have a name attribute');
        }

        const html = document.createElement('html');
        const body = document.createElement('body');
        const form = this.createPaReqForm('threeDS1PaReqForm', acsUrl, container.name, paReq, termUrl);

        body.appendChild(form);
        html.appendChild(body);
        container.appendChild(html);
        // container.style.display = 'none';

        form.submit();

        return container;
    };

    /**
     * Initiates a 3DS Challenge request and submits the form the the ACS URL.
     *
     * @param acsUrl - the FQDN URL to submit the Challenge Request
     * @param creqData - Base64-encoded Challenge Request
     * @param container - the iframe container where the form will be attached to. The container must have the 'name'
     *                    attribute set
     * @throws {Error} - throws error if there is a validation error
     * @returns {HTMLIFrameElement} - the container
     */
    this.init3DSChallengeRequest = function (acsUrl, creqData, container) {

        if (!acsUrl || !creqData || !container) {
            throw Error('Not all required fields have value');
        }
        if (!(container instanceof HTMLIFrameElement)) {
            throw Error('Container is not of type iframe');
        }
        if (!container.name) {
            throw Error('Container must have a name attribute');
        }

        const html = document.createElement('html');
        const body = document.createElement('body');
        const form = this.createForm('challengeRequestForm', acsUrl, container.name, "creq", creqData);

        body.appendChild(form);
        html.appendChild(body);
        container.appendChild(html);

        form.submit();

        return container;

    };
    /**
     * Creates an iframe, attach it to the rootContainer and submit 3DS Method form.
     *
     * @param threeDSMethodUrl - a FQDN endpoint to submit the 3DS Method request
     * @param threeDSMethodData - Base64-encoded 3DS Method Data value
     * @param frameName - name of the frame container. if not set it will be set to 'threeDSMethodIFrame'
     * @param rootContainer - the container where the iframe will be attached to.
     *                        If not set defaults to the JavaScript document.body object
     * @param onFrameLoadCallback - callback function attached to the iframe.onload event
     * @throws {Error} - throws error if there is a validation error
     * @returns {HTMLIFrameElement} - returns the generated iframe element
     */
    this.createIframeAndInit3DSMethod = function (threeDSMethodUrl, threeDSMethodData, frameName, rootContainer, onFrameLoadCallback) {
        frameName = frameName || 'threeDSMethodIFrame';
        rootContainer = rootContainer || document.body;

        const iFrame = this.createIframe(rootContainer, frameName, 'threeDSMethodIframe', '0', '0', onFrameLoadCallback);
        this.init3DSMethod(threeDSMethodUrl, threeDSMethodData, iFrame);
        return iFrame;
    };

    /**
     * Creates an iframe, attach it to the rootContainer and submits 3DS Challenge Request.
     * @param acsUrl - the FQDN URL to submit the Challenge Request
     * @param creqData - Base64-encoded Challenge Request
     * @param challengeWindowSize - EMVCo assigned window size.
     *                              '01' -> 250px x 400px,
     *                              '02' -> 390px x 400px,
     *                              '03' -> 500px x 600px,
     *                              '04' -> 600px x 400px,
     *                              '05' -> Full screen, or full container content
     * @param frameName - name of the frame container. if not set it will be set to 'threeDSCReqIFrame'
     * @param rootContainer - the container where the iframe will be attached to.
     *                        If not set defaults to the JavaScript document.body object
     * @param onFrameLoadCallback - callback function attached to the iframe.onload event
     * @throws {Error} - throws error if there is a validation error
     * @returns {HTMLIFrameElement} - returns the generated iframe element
     */
    this.createIframeAndInit3DSChallengeRequest = function (acsUrl, creqData, challengeWindowSize, frameName, rootContainer, onFrameLoadCallback) {
        challengeWindowSize = challengeWindowSize || '05';
        frameName = frameName || "threeDSCReqIFrame";
        rootContainer = rootContainer || document.body;

        const windowSize = this.getWindowSize(challengeWindowSize);
        const iFrame = this.createIframe(rootContainer, frameName, 'threeDSCReqIframe', '100%', '100%', onFrameLoadCallback);
        this.init3DSChallengeRequest(acsUrl, creqData, iFrame);
        return iFrame;
    };

    /**
     * Creates an iframe, attach it to the rootContainer and submits 3DS Challenge Request.
     * @param acsUrl - the FQDN URL to submit the Challenge Request
     * @param paReq - Base64-encoded Payer Authentication Request
     * @param termUrl - URL to which the Card Issuer ACS will POST the PaRes back to.
     * @param frameName - name of the frame container. if not set it will be set to 'threeDS1PaReq'
     * @param rootContainer - the container where the iframe will be attached to.
     *                        If not set defaults to the JavaScript document.body object
     * @param onFrameLoadCallback - callback function attached to the iframe.onload event
     * @throws {Error} - throws error if there is a validation error
     * @returns {HTMLIFrameElement} - returns the generated iframe element
     */
    this.createIframeAndInit3DS1PaReq = function (acsUrl, paReq, termUrl, frameName, rootContainer, onFrameLoadCallback) {
        frameName = frameName || 'threeDS1PaReq';
        rootContainer = rootContainer || document.body;

        const windowSize = this.getWindowSize('02');
        const iframe = this.createIframe(rootContainer, frameName, 'threeDS1PaReq', '100%', '100%', onFrameLoadCallback);
        this.init3DS1PaReq(acsUrl, paReq, termUrl, iframe);
        return iframe;
    };
}

// END SNIPPET: websdk-documentation

window.TDSWebSDK = TDSWebSDK;