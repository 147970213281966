import { Box, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface CarrierModalProps {
  carrier: string;
  onAnimated: () => void; // Add this line
}

export default function CarrierModal({
  carrier,
  onAnimated,
}: CarrierModalProps) {
  const { t } = useTranslation();
  const [logo, setLogo] = useState<any | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    import(`../assets/carriers/${carrier}.svg`)
      .then((logoImport) => {
        setLogo(logoImport.default);
        setOpen(true);
        // Set a timer to close the modal after 3 seconds
        const timer = setTimeout(() => {
          setOpen(false);
          onAnimated();
        }, 3000);
        // Clear the timer when the component is unmounted
        return () => clearTimeout(timer);
      })
      .catch(() => {
        console.error(`Logo not found for carrier: ${carrier}`);
        setLogo(null);
        onAnimated();
      });
  }, [carrier]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography>{t("carrier-title")}</Typography>
        {logo && (
          <Box
            component="img"
            sx={{ maxHeight: "4rem" }}
            src={logo}
            alt={carrier}
          />
        )}
      </Stack>
    </Modal>
  );
}
