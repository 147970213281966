import { Box } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../../../../assets/css/common/3ds.css";
import "../../../../assets/js/common/3ds/3ds-web";
import "../../../../assets/js/common/3ds/3ds-web";
import Axepta from "../../../../assets/js/common/3ds/axepta";
import "../../../../assets/js/common/3ds/threeds";

declare global {
  interface Window {
    axepta: boolean;
  }
}
window.axepta = false;

interface RedirectParam {
  name: string;
  value: string;
}

const ThreeDSHandler = () => {
  const location = useLocation();
  const state = location.state;
  const axepta = state.axepta;
  const alto = state.alto;

  useEffect(() => {
    if (axepta) {
      const loadingScreen = document.getElementById("loading_screen");
      if (loadingScreen) {
        loadingScreen.className = "loading-screen";
      }

      threeds.setEndpoints(
        axepta.errorUrl,
        axepta.authenticationUrl,
        axepta.successUrl,
        axepta.notificationUrl
      );
      threeds.bind(document.getElementById("rootContainer"));
      threeds.setChallengeLoad(() => {
        document.getElementById("loading_screen").className =
          "loading-screen hide";
      });

      window.axepta = true;
      threeds.handleResponse(axepta.response);
    } else if (alto) {
      const form = document.getElementsByName(
        "ecardon3dsForm"
      )[0] as HTMLFormElement;
      form.submit();
    }
  }, []);

  if (alto) {
    return (
      <form
        action={alto.redirectUrl}
        className="paymentWidgets"
        name="ecardon3dsForm"
        method="post"
      >
        {alto.redirectData.map((param: RedirectParam, index: number) => (
          <input
            key={index}
            type="hidden"
            name={param.name}
            value={param.value}
          />
        ))}
      </form>
    );
  } else {
    return (
      <Box sx={{ height: "500px" }}>
        <Axepta />
        <div id="rootHolder">
          <div id="rootContainer"></div>
        </div>
      </Box>
    );
  }
};

export default ThreeDSHandler;
