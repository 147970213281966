interface IPhone {
  color: string;
}

export default function Phone({ color }: IPhone) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 120 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3316_739)">
        <rect
          x="20"
          y="15"
          width="80"
          height="80"
          rx="12"
          fill="white"
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip0_3316_711)">
          <path
            d="M50.5458 78.0409C49.6738 78.0409 48.8375 77.6945 48.2208 77.0779C47.6042 76.4613 47.2578 75.625 47.2578 74.7529V35.2489C47.2578 34.3769 47.6042 33.5406 48.2208 32.924C48.8375 32.3074 49.6738 31.9609 50.5458 31.9609H69.4578C70.3298 31.9609 71.1662 32.3074 71.7828 32.924C72.3994 33.5406 72.7458 34.3769 72.7458 35.2489V74.7529C72.7458 75.625 72.3994 76.4613 71.7828 77.0779C71.1662 77.6945 70.3298 78.0409 69.4578 78.0409H50.5458ZM48.8658 74.7529C48.8658 74.9724 48.9094 75.1898 48.9941 75.3923C49.0788 75.5948 49.203 75.7784 49.3593 75.9325C49.5156 76.0866 49.701 76.208 49.9047 76.2898C50.1084 76.3716 50.3263 76.4121 50.5458 76.4089H69.4578C69.6773 76.4121 69.8952 76.3716 70.0989 76.2898C70.3026 76.208 70.488 76.0866 70.6443 75.9325C70.8007 75.7784 70.9248 75.5948 71.0095 75.3923C71.0942 75.1898 71.1378 74.9724 71.1378 74.7529V69.8329H48.8658V74.7529ZM71.1378 68.2249V38.7529H48.8658V68.2249H71.1378ZM71.1378 37.1449V35.2489C71.1378 35.0294 71.0942 34.8121 71.0095 34.6096C70.9248 34.4071 70.8007 34.2235 70.6443 34.0694C70.488 33.9153 70.3026 33.7938 70.0989 33.712C69.8952 33.6302 69.6773 33.5898 69.4578 33.5929H50.5458C50.3263 33.5898 50.1084 33.6302 49.9047 33.712C49.701 33.7938 49.5156 33.9153 49.3593 34.0694C49.203 34.2235 49.0788 34.4071 48.9941 34.6096C48.9094 34.8121 48.8658 35.0294 48.8658 35.2489V37.1449H71.1378Z"
            fill={color}
          />
          <path
            d="M59.9983 75.807C59.4856 75.807 58.9845 75.655 58.5582 75.3702C58.132 75.0854 57.7997 74.6806 57.6036 74.207C57.4074 73.7333 57.356 73.2122 57.4561 72.7094C57.5561 72.2066 57.8029 71.7447 58.1654 71.3822C58.5279 71.0197 58.9898 70.7729 59.4926 70.6729C59.9954 70.5728 60.5165 70.6242 60.9902 70.8204C61.4638 71.0165 61.8686 71.3488 62.1534 71.775C62.4382 72.2013 62.5903 72.7024 62.5903 73.215C62.5903 73.9025 62.3172 74.5618 61.8311 75.0479C61.345 75.534 60.6857 75.807 59.9983 75.807ZM59.9983 72.255C59.8017 72.2503 59.6082 72.3046 59.4427 72.4108C59.2773 72.5171 59.1475 72.6705 59.07 72.8513C58.9926 73.032 58.9711 73.2318 59.0083 73.4249C59.0455 73.618 59.1397 73.7955 59.2787 73.9346C59.4178 74.0736 59.5953 74.1678 59.7884 74.205C59.9815 74.2422 60.1813 74.2207 60.362 74.1433C60.5428 74.0658 60.6962 73.936 60.8025 73.7706C60.9087 73.6051 60.963 73.4116 60.9583 73.215C60.9583 72.9604 60.8571 72.7163 60.6771 72.5362C60.497 72.3562 60.2529 72.255 59.9983 72.255Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3316_739"
          x="0"
          y="0"
          width="120"
          height="120"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3316_739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3316_739"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3316_739">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(36 31)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
