export default function Signal({ color }: { color: string }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.00610314V1.58691C0.0122078 2.44141 0.708008 3.125 1.5625 3.1311C1.5686 3.125 1.57471 3.125 1.58081 3.125V3.16773C9.35059 3.16773 15.625 9.44214 15.625 17.2119C15.6311 18.0664 16.3269 18.7561 17.1875 18.75H17.2058H18.75V17.2119H18.7439C18.7439 17.2058 18.7439 17.2058 18.75 17.1997C18.7439 7.86743 11.2427 0.256348 1.95923 0.0549313C1.83716 0.0244141 1.70898 0.00610314 1.58081 0.00610314V0L0 0.00610314ZM0 6.2561V7.83691C0.0122078 8.69141 0.708008 9.375 1.5625 9.3811C1.5686 9.375 1.57471 9.375 1.58081 9.375V9.39941C5.82886 9.39941 9.25903 12.7502 9.38721 16.9678C9.375 17.041 9.3689 17.1143 9.3689 17.1936C9.3689 18.0542 10.0708 18.7561 10.9314 18.7561C10.9436 18.75 10.9497 18.75 10.9558 18.75V18.7561H12.4939V17.2119H12.5183C12.5183 11.2854 7.75757 6.4331 1.86157 6.28662C1.86157 6.28052 1.85547 6.28052 1.85547 6.28052C1.76392 6.26221 1.67236 6.2561 1.58081 6.2561V6.25L0 6.2561ZM3.125 12.5061C1.3977 12.5061 0 13.9038 0 15.6311C0 17.3523 1.3977 18.7561 3.125 18.7561C4.84619 18.7561 6.25 17.3523 6.25 15.6311C6.25 13.9038 4.84619 12.5061 3.125 12.5061Z"
        fill={color}
      />
    </svg>
  );
}
