import { API_PATH_UNSUBSCRIBE } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  CenteredCardSubtitle,
  CenteredCardSuccessBox,
  CenteredCardTitle,
} from "@cospex/client/tracker/components/CenteredCardTitle";
import { ShadowCard } from "@cospex/client/tracker/components/Layout";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().min(1, "required").email("login-email-invalid"),
});

type UnsubscribeData = {
  email: string;
};

const Unsubscribe = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<UnsubscribeData>({
    resolver: zodResolver(validationSchema),
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [unsubscribedSuccessfully, setUnsubscribedSuccessfully] =
    useState(false);

  const [error, setError] = useState("");

  const mutation = useMutation({
    mutationFn: async ({ email }: UnsubscribeData) => {
      if (!recaptchaRef.current) {
        throw new Error("reCAPTCHA not loaded");
      }
      const reCaptchaResult = await recaptchaRef.current.executeAsync();
      return axios.post(
        API_PATH_UNSUBSCRIBE,
        {
          formData: {
            email,
            ["g-recaptcha-response"]: reCaptchaResult,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: ({ data: { unsubscribed } }) => {
      if (unsubscribed) {
        setUnsubscribedSuccessfully(true);
      }
    },
    onError(res: any) {
      const error = res.response?.data?.error || res.message;
      setError(error);
    },
  });

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        minHeight: {
          md: "74vh",
          xs: "50vh",
        },
      }}
    >
      <ShadowCard
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: 690,
          p: {
            md: 8,
            xs: 2,
          },
        }}
      >
        <CenteredCardSuccessBox
          show={unsubscribedSuccessfully}
          textComponent={t("unsubscription-successful")}
        />
        <CenteredCardTitle text={t("unsubscribe-title")} />
        <br />
        <CenteredCardSubtitle text={t("unsubscribe-subtitle")} />
        <Box
          component="form"
          onSubmit={handleSubmit((data: UnsubscribeData) =>
            mutation.mutate(data)
          )}
          width="100%"
          mt="1rem"
        >
          <Stack spacing={3} sx={{ width: "100%" }}>
            <TextInput
              name="email"
              control={control}
              label={t("login-email")}
              formState={formState}
              fullWidth
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
              size="invisible"
              style={{
                margin: 0,
              }}
            />
            <Button variant="contained" type="submit">
              <Typography color="white">{t("cancel-subscription")}</Typography>
            </Button>
            {error && <Alert severity="error">{t(error)}</Alert>}
          </Stack>
        </Box>
      </ShadowCard>
    </Stack>
  );
};

export default Unsubscribe;
