import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  AndroidActivateFindMy,
  AndroidBlockDelete,
  AndroidFindLostOrStolen,
  AndroidFindLostPhone,
} from "@cospex/client/tracker/components/AndroidTutorial";
import { FloatingCard } from "@cospex/client/tracker/components/Layout";
import {
  StyledTabPanel,
  TutorialTab,
} from "@cospex/client/tracker/components/TutorialTab";

export default function TutorialSamsung() {
  const { t } = useTranslation();

  return (
    <FloatingCard sx={{ mb: 3 }}>
      <TutorialTab title={t("dashboard-tutorial-samsung-title")}>
        <StyledTabPanel value={0}>
          <AndroidFindLostOrStolen />
        </StyledTabPanel>
        <StyledTabPanel value={1}>
          <AndroidFindLostPhone />
        </StyledTabPanel>
        <StyledTabPanel value={2}>
          <AndroidBlockDelete />
        </StyledTabPanel>
        <StyledTabPanel value={3}>
          <AndroidActivateFindMy />
        </StyledTabPanel>
      </TutorialTab>
    </FloatingCard>
  );
}
