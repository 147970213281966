import useTranslation from "@cospex/client/hooks/useTranslation";
import Home from "@cospex/client/tracker/components/Home";

export default function Index() {
  const { t } = useTranslation();
  return (
    <Home
      title={t("home-title-samsung-galaxy")}
      titleFontSize={{ xs: "33px", sm: "60px" }}
    />
  );
}
