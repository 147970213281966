import { getRandomValueScaledToDaytime } from "@cospex/client/utils/daytimeScaler";
import { Circle } from "@mui/icons-material";
import { SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface ICounterContainer {
  children: (count: string, previousCount: string) => React.ReactNode;
  sx?: SxProps<Theme>;
}

export function CounterContainer({ children }: ICounterContainer) {
  const dateKey = new Date().toLocaleDateString("en-GB");
  const countKey = dateKey + "_counter";

  const cachedCount = () => {
    const cache = localStorage.getItem(countKey);
    return cache ? parseInt(cache) : null;
  };

  const [count, setCount] = useState(
    cachedCount() || getRandomValueScaledToDaytime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() < 0.5) {
        setCount((prevCount) => Math.min(prevCount + 1, 9999));
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(countKey, count.toString());
  }, [count]);

  return <>{children(count.toString(), (count - 1).toString())}</>;
}

export default function Counter({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}) {
  return (
    <CounterContainer>
      {(count) => (
        <Typography
          sx={{
            bgcolor: "#EFEDED",
            p: "10px",
            mt: "1rem",
            maxWidth: "max-content",
            borderRadius: "10px",
            ...sx,
          }}
        >
          <Circle
            sx={{
              fontSize: "0.6rem",
              verticalAlign: "center",
            }}
          />{" "}
          {children} <b>{count}</b>
        </Typography>
      )}
    </CounterContainer>
  );
}
