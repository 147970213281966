import useTranslation from "@cospex/client/hooks/useTranslation";
import { Alert, Box, Container, Skeleton } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGf from "remark-gfm";

const Policy = ({
  type,
}: {
  type: "terms" | "privacy" | "cookies" | "refunds";
}) => {
  const { i18n } = useTranslation();
  const [text, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    axios
      .get(`/api/${type}-policy?language=${i18n.language}`)
      .then(({ data: { content } }) => {
        setContent(content);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [i18n.language, type]);

  return (
    <Container>
      <Box sx={{ marginBottom: "2rem", minHeight: "100vh" }}>
        {loading && (
          <Box p={2}>
            <Skeleton variant="text" width="60%" height={30} />
            <Skeleton variant="text" width="90%" height={30} />
            <Skeleton variant="text" width="70%" height={30} />
            <Skeleton variant="text" width="85%" height={30} />
            <Skeleton variant="text" width="95%" height={30} />
            <Skeleton
              variant="text"
              width="50%"
              height={30}
              sx={{
                mb: 2,
              }}
            />
            <Skeleton variant="text" width="75%" height={30} />
            <Skeleton variant="text" width="65%" height={30} />
            <Skeleton variant="text" width="80%" height={30} />
          </Box>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <ReactMarkdown remarkPlugins={[remarkGf]}>{text}</ReactMarkdown>
        )}
      </Box>
    </Container>
  );
};

export default Policy;
