import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box } from "@mui/material";

import mastercardPath from "../assets/mastercard.svg";
import obPath from "../assets/ob.svg";
import sslPath from "../assets/ssl.svg";
import visaPath from "../assets/visa.svg";

export default function PaymentLogos() {
  const { i18n } = useTranslation();
  const showOb = i18n.language === "fr";
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap={2}
      sx={{
        height: 24, //overall dimensions controlled by element height
        "& > img": {
          width: "auto",
          height: "100%",
        },
      }}
    >
      <Box component="img" src={visaPath} alt="Visa" />
      <Box component="img" src={mastercardPath} alt="MasterCard" />
      {showOb && <Box component="img" src={obPath} alt="OB" />}
      <Box component="img" src={sslPath} alt="SSL" />
    </Box>
  );
}
