import useTranslation from "@cospex/client/hooks/useTranslation";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Modal, Stack, Typography } from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";

interface ISearchPhoneModalProps {
  onAnimated?: () => void;
  animationData: Record<any, any>;
}

export default function SearchPhoneModal({
  onAnimated,
  animationData,
}: ISearchPhoneModalProps) {
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const { t } = useTranslation();

  const items = [t("animation-1"), t("animation-3"), t("animation-2")];

  const getDelay = (i: number) => {
    return 1000 * i;
  };
  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];
    items.forEach((item, index) => {
      const delay = getDelay(index);
      const timeout = setTimeout(() => {
        setVisibleItems((prevItems) => [...prevItems, item]);
      }, index * delay);
      timeouts.push(timeout);
    });
    return () => {
      setVisibleItems([]);
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  useEffect(() => {
    if (visibleItems.length === items.length) {
      const timeout = setTimeout(() => {
        onAnimated?.();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [visibleItems.length]);

  const transitions = useTransition(visibleItems, {
    from: { opacity: 0, transform: "translate3d(0, -40px, 0)" },
    enter: { opacity: 1, transform: "translate3d(0, 0px, 0)" },
  });

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography>{t("animation-title")}</Typography>
        <Stack
          sx={{
            alignItems: {
              md: "center",
              sm: "flex-start",
            },
            flexDirection: {
              md: "row",
              sm: "column",
            },
          }}
        >
          <Lottie
            animationData={animationData}
            loop={true}
            style={{
              width: 477 / 2,
              height: 540 / 2,
            }}
          />
          <Stack
            gap={2}
            sx={{
              width: "280px",
              height: "160px",
              ml: {
                md: 4,
                sm: 0,
              },
            }}
          >
            {transitions((style, item) => (
              <animated.div key={item} style={style}>
                <Stack alignItems="center" direction="row">
                  <CheckCircleOutline color="success" sx={{ mr: 2 }} />
                  {item}
                </Stack>
              </animated.div>
            ))}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
