import AndroidDirectionImg from "@cospex/client/assets/tracker/tutorial/android-display-device-on-map.png";
import AndroidFindActivateLocalizationImg from "@cospex/client/assets/tracker/tutorial/android-find-activate-localization.png";
import AndroidFindMyImg from "@cospex/client/assets/tracker/tutorial/android-find-my-device.png";
import AndroidPlayImg from "@cospex/client/assets/tracker/tutorial/android-google-play.png";
import AndroidThumbsImg from "@cospex/client/assets/tracker/tutorial/android-thumbs.png";
import AndroidEraseDeviceEnImg from "@cospex/client/assets/tracker/tutorial/en/delete.png";
import AndroidPlaySoundEnImg from "@cospex/client/assets/tracker/tutorial/en/entete.png";
import AndroidSecureDeviceEnImg from "@cospex/client/assets/tracker/tutorial/en/secure.png";
import AndroidEraseDeviceFrImg from "@cospex/client/assets/tracker/tutorial/fr/delete.png";
import AndroidPlaySoundFrImg from "@cospex/client/assets/tracker/tutorial/fr/entete.png";
import AndroidSecureDeviceFrImg from "@cospex/client/assets/tracker/tutorial/fr/secure.png";
import AndroidEraseDeviceItImg from "@cospex/client/assets/tracker/tutorial/it/delete.png";
import AndroidPlaySoundItImg from "@cospex/client/assets/tracker/tutorial/it/entete.png";
import AndroidSecureDeviceItImg from "@cospex/client/assets/tracker/tutorial/it/secure.png";
import { StyledLI, StyledOL } from "@cospex/client/components/List";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, Typography } from "@mui/material";

export function AndroidFindLostOrStolen() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Typography my="1.5rem" variant="h5">
        {t("dashboard-tutorial-android-find-lost-locate")}
      </Typography>
      <br />
      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Stack flex="1 1 0">
          <Stack height="200px" alignItems="center">
            <Box
              component="img"
              width="110px"
              height="auto"
              src={AndroidFindMyImg}
            />
          </Stack>
          <br />
          <Typography variant="h6">
            {t("dashboard-tutorial-android-find-lost-show-phone")}
          </Typography>
          <StyledOL>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-show-phone-one")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-show-phone-two")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-show-phone-three")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-show-phone-four")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-show-phone-five")}
            </StyledLI>
          </StyledOL>
        </Stack>
        <Stack flex="1 1 0">
          <Box height="200px" alignItems="center">
            <Box
              component="img"
              width="180px"
              height="172px"
              src={AndroidDirectionImg}
            />
          </Box>
          <br />
          <Typography variant="h6">
            {t("dashboard-tutorial-android-find-lost-directions")}
          </Typography>
          <StyledOL>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-directions-one")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-directions-two")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-directions-three")}
            </StyledLI>
          </StyledOL>
        </Stack>
        <Stack flex="1 1 0" maxWidth="220px">
          <Box height="200px" alignItems="center">
            <Box
              component="img"
              width="180px"
              height="172px"
              src={
                i18n.language === "en"
                  ? AndroidPlaySoundEnImg
                  : i18n.language === "fr"
                  ? AndroidPlaySoundFrImg
                  : i18n.language === "it"
                  ? AndroidPlaySoundItImg
                  : AndroidPlaySoundEnImg
              }
            />
          </Box>
          <br />
          <Typography variant="h6">
            {t("dashboard-tutorial-android-find-lost-ring")}
          </Typography>
          <StyledOL>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-ring-one")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-ring-two")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-ring-three")}
            </StyledLI>
            <StyledLI>
              {t("dashboard-tutorial-android-find-lost-ring-four")}
            </StyledLI>
          </StyledOL>
        </Stack>
      </Stack>
    </>
  );
}

export function AndroidFindLostPhone() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Typography my="1.5rem" variant="h5">
        {t("dashboard-tutorial-android-activate-lost-mode")}
      </Typography>
      <br />
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: "2rem",
        }}
      >
        <Box
          component="img"
          width="280px"
          height="563px"
          src={
            i18n.language === "en"
              ? AndroidSecureDeviceEnImg
              : i18n.language === "fr"
              ? AndroidSecureDeviceFrImg
              : i18n.language === "it"
              ? AndroidSecureDeviceItImg
              : AndroidSecureDeviceEnImg
          }
        />
        <Stack>
          <Typography>
            {t("dashboard-tutorial-android-activate-lost-mode-desc-one")}
          </Typography>
          <Typography>
            {t("dashboard-tutorial-android-activate-lost-mode-desc-two")}
          </Typography>
          <Stack flexDirection="row" gap="1rem">
            <Stack sx={{ flex: "1 1 0" }}>
              <Typography variant="h6" sx={{ mt: "2rem", mb: "1rem" }}>
                {t("dashboard-tutorial-android-lost-mode-direction-title")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-direction-one")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-direction-two")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-direction-three")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-direction-four")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-direction-five")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-direction-six")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-direction-seven")}
              </Typography>
            </Stack>
            <Stack sx={{ flex: "1 1 0" }}>
              <Typography variant="h6" sx={{ mt: "2rem", mb: "1rem" }}>
                {t("dashboard-tutorial-android-lost-mode-ring-title")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-ring-one")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-lost-mode-ring-two")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export function AndroidBlockDelete() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Typography my="1.5rem" variant="h5">
        {t("dashboard-tutorial-android-block-delete")}
      </Typography>
      <br />
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: "2rem",
        }}
      >
        <Box
          component="img"
          width="280px"
          height="563px"
          src={
            i18n.language === "en"
              ? AndroidEraseDeviceEnImg
              : i18n.language === "fr"
              ? AndroidEraseDeviceFrImg
              : i18n.language === "it"
              ? AndroidEraseDeviceItImg
              : AndroidEraseDeviceEnImg
          }
        />
        <Stack>
          <Typography>
            {t("dashboard-tutorial-android-block-delete-desc-one")}
          </Typography>
          <Typography>
            {t("dashboard-tutorial-android-block-delete-desc-two")}
          </Typography>
          <Stack flexDirection="row" gap="1rem">
            <Stack sx={{ flex: "1 1 0" }}>
              <Typography variant="h6" sx={{ mt: "2rem", mb: "1rem" }}>
                {t("dashboard-tutorial-android-block-delete-clear-title")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-block-delete-clear-one")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-block-delete-clear-two")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-block-delete-clear-three")}
              </Typography>
              <Typography>
                {t("dashboard-tutorial-android-block-delete-clear-four")}
              </Typography>
            </Stack>
            <Stack sx={{ flex: "1 1 0" }}>
              <Typography variant="h6" sx={{ mt: "2rem", mb: "1rem" }}>
                {t("dashboard-tutorial-android-block-delete-caution-title")}
              </Typography>
              <Typography>
                <strong>
                  {t("dashboard-tutorial-android-block-delete-caution-one")}
                </strong>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export function AndroidActivateFindMy() {
  const { t } = useTranslation();
  return (
    <>
      <Typography my="1.5rem" variant="h5">
        {t("dashboard-tutorial-android-find-my")}
      </Typography>
      <Typography>{t("dashboard-tutorial-android-find-my-one")}</Typography>
      <Typography>{t("dashboard-tutorial-android-find-my-two")}</Typography>
      <Typography>{t("dashboard-tutorial-android-find-my-three")}</Typography>
      <Typography>{t("dashboard-tutorial-android-find-my-four")}</Typography>
      <Typography>{t("dashboard-tutorial-android-find-my-five")}</Typography>
      <Typography>{t("dashboard-tutorial-android-find-my-six")}</Typography>
      <Typography>{t("dashboard-tutorial-android-find-my-seven")}</Typography>
      <br />
      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
          columnGap: "2rem",
          rowGap: "1rem",
        }}
      >
        <Stack sx={{ flexDirection: "row", width: "400px", gap: "1rem" }}>
          <Stack>
            <Stack flexDirection="row" gap="10px">
              <Typography variant="h6">
                {t("dashboard-tutorial-android-find-my-check-activation")}
              </Typography>
            </Stack>
            <br />
            <Typography>
              {t("dashboard-tutorial-android-find-my-check-activation-one")}
            </Typography>
            <Typography>
              {t("dashboard-tutorial-android-find-my-check-activation-two")}
            </Typography>
            <Typography>
              {t("dashboard-tutorial-android-find-my-check-activation-three")}
            </Typography>
          </Stack>
          <Box
            component="img"
            width="80px"
            height="80px"
            m="1rem"
            src={AndroidFindMyImg}
          />
        </Stack>
        <Stack sx={{ flexDirection: "row", width: "400px", gap: "1rem" }}>
          <Stack>
            <Stack flexDirection="row" gap="10px">
              <Typography variant="h6">
                {t("dashboard-tutorial-android-find-my-localization-mode")}
              </Typography>
            </Stack>
            <br />
            <Typography>
              {t("dashboard-tutorial-android-find-my-localization-mode-one")}
            </Typography>
            <Typography>
              {t("dashboard-tutorial-android-find-my-localization-mode-two")}
            </Typography>
            <Typography>
              {t("dashboard-tutorial-android-find-my-localization-mode-three")}
            </Typography>
          </Stack>
          <Box
            component="img"
            width="80px"
            height="80px"
            mt="1rem"
            src={AndroidFindActivateLocalizationImg}
          />
        </Stack>
        <Stack sx={{ flexDirection: "row", width: "400px", gap: "1rem" }}>
          <Stack>
            <Stack flexDirection="row" gap="10px">
              <Typography variant="h6">
                {t("dashboard-tutorial-android-find-my-device-visibility")}
              </Typography>
            </Stack>
            <br />
            <Typography>
              {t("dashboard-tutorial-android-find-my-device-visibility-one")}
            </Typography>
            <Typography>
              {t("dashboard-tutorial-android-find-my-device-visibility-two")}
            </Typography>
            <Typography>
              {t("dashboard-tutorial-android-find-my-device-visibility-three")}
            </Typography>
          </Stack>
          <Box
            component="img"
            width="80px"
            height="80px"
            mt="1rem"
            src={AndroidPlayImg}
          />
        </Stack>
        <Stack sx={{ flexDirection: "row", width: "400px", gap: "1rem" }}>
          <Stack>
            <Stack flexDirection="row" gap="10px">
              <Typography variant="h6">
                {t("dashboard-tutorial-android-find-my-actual-check")}
              </Typography>
            </Stack>
            <br />
            <Typography>
              {t("dashboard-tutorial-android-find-my-actual-check-one")}
            </Typography>
            <Typography>
              {t("dashboard-tutorial-android-find-my-actual-check-two")}
            </Typography>
            <Typography>
              {t("dashboard-tutorial-android-find-my-actual-check-three")}
            </Typography>
          </Stack>
          <Box
            component="img"
            width="80px"
            height="80px"
            mt="1rem"
            src={AndroidThumbsImg}
          />
        </Stack>
      </Stack>
    </>
  );
}
