import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import PaymentForm from "@cospex/client/forms/payment";
import { getItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  Section,
  SectionSubtitle,
  SectionTitle,
  ShadowCard,
} from "@cospex/client/tracker/components/Layout";
import PaymentStepper from "@cospex/client/tracker/components/PaymentStepper";
import PaymentStepperMobile from "@cospex/client/tracker/components/PaymentStepperMobile";
import Pricing from "@cospex/client/tracker/components/Pricing";
import PhonesLocated from "@cospex/client/tracker/components/sections/PhonesLocated";
import LocationImage from "@cospex/client/tracker/img/location.png";
import LocationPinImage from "@cospex/client/tracker/img/location_pin.svg";
import PlaceMarkerImg from "@cospex/client/tracker/img/place-marker.png";
import {
  Alert,
  Box,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const AnimatedPin = styled(Box)`
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: bounce 1s ease-in-out infinite;
`;

const BlurText = styled(Box)({
  textShadow: "0 0 8px #000",
  color: "transparent",
});

const PhoneToLocate = ({ onboardingNumber }: { onboardingNumber: string }) => {
  const { t } = useTranslation();
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      pb={3}
      gap={1}
    >
      <Typography variant="h5" sx={{ color: "primary.main" }}>
        <b>{t("phone-to-locate")}</b>
      </Typography>
      <Typography variant="h5">
        <b>{onboardingNumber}</b>
      </Typography>
    </Stack>
  );
};
const Payment = () => {
  const { t } = useTranslation();
  const onboardingNumber = getItemWithExpiry("onboardingNumber");
  const onBoardingProvider = getItemWithExpiry("onBoardingProvider");
  const email = getItemWithExpiry("onboardingEmail");
  const id = getItemWithExpiry("onboardingId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const noDetailsError = !onboardingNumber || !email || !id;

  if (noDetailsError) {
    return (
      <Container>
        <Alert severity="error">
          Error, please go <Link href="/">home</Link> and try again.
        </Alert>
      </Container>
    );
  }

  return (
    <>
      {isMobile ? (
        <>
          <PaymentStepperMobile />
          <Box sx={{ mt: 4 }}>
            <PhoneToLocate onboardingNumber={onboardingNumber} />
          </Box>
        </>
      ) : (
        <PaymentStepper />
      )}
      <Container
        sx={{
          p: {
            xs: 0,
            md: 4,
          },
        }}
      >
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
            }}
          >
            <ShadowCard
              sx={{
                display: "flex",
                justifyContent: "center",
                flex: 1,
                borderRadius: {
                  xs: 0,
                  md: "12px",
                },
                borderWidth: {
                  xs: 0,
                  md: "1px 1px 0 1px",
                },
                borderColor: `color-mix(in srgb, ${theme.palette.primary.main} 32%, white)`,
                borderStyle: "solid",
                p: 0,
              }}
            >
              <PaymentForm onPaymentSuccessRedirectPath="/dashboard/sms" />
            </ShadowCard>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                px: {
                  xs: 4,
                  md: 0,
                },
              }}
            >
              <ShadowCard
                sx={{
                  height: "100%",
                  alignItems: "center",
                  borderWidth: "1px 1px 0 1px",
                  borderColor: `color-mix(in srgb, ${theme.palette.primary.main} 32%, white)`,
                  borderStyle: "solid",
                }}
              >
                {!isMobile && (
                  <PhoneToLocate onboardingNumber={onboardingNumber} />
                )}
                <Box
                  display="block"
                  width="100%"
                  sx={{
                    height: 192,
                    backgroundImage: `url(${LocationImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: 2,
                    position: "relative",
                  }}
                  mb={4}
                >
                  <AnimatedPin
                    sx={{
                      backgroundImage: `url(${LocationPinImage})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "none",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      top: -60,
                      margin: "auto",
                      width: 85 / 1.5,
                      height: 115 / 1.5,
                    }}
                  />
                </Box>
                <Grid container mb={5.5} sx={{ textAlign: "center" }}>
                  <Grid item xs={6}>
                    <Stack rowGap="1rem">
                      <Box>
                        <Typography variant="body1">
                          {t("phone-location-graphic-provider")}:
                        </Typography>
                        {onBoardingProvider || <BlurText>xxxxx</BlurText>}
                      </Box>
                      <Box>
                        <Typography variant="body1">
                          {t("phone-location-graphic-city")}:
                        </Typography>
                        <BlurText>xxxxx</BlurText>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack rowGap="1rem">
                      <Box>
                        <Typography variant="body1">
                          {t("phone-location-graphic-phone-number")}:
                        </Typography>
                        {onboardingNumber}
                      </Box>
                      <Box>
                        <Typography variant="body1">
                          {t("phone-location-graphic-street")}:
                        </Typography>
                        <BlurText>xxxxx</BlurText>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack flexDirection="row" mt="1.5rem">
                  <Stack justifyContent="center" m="1rem">
                    <Box
                      component="img"
                      height="24px"
                      width="24px"
                      src={PlaceMarkerImg}
                      alt="place marker"
                    />
                  </Stack>
                  <Stack gap="5px">
                    <Typography variant="h6">
                      {t("phone-location-precise")}
                    </Typography>
                    <Typography variant="body2">
                      {t("phone-location-precise-desc")}
                    </Typography>
                  </Stack>
                </Stack>
              </ShadowCard>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Section
        filled
        sx={{
          py: 8,
        }}
      >
        <SectionSubtitle>{t("home-testimonials-subtitle")}</SectionSubtitle>
        <SectionTitle>{t("home-testimonials-title")}</SectionTitle>
        <ReviewCarousel />
      </Section>
      <PhonesLocated />
      <Section>
        <Stack alignItems="center">
          <Pricing />
        </Stack>
      </Section>
    </>
  );
};

export default Payment;
