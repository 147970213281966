import { Box, Link } from "@mui/material";
import { SxProps } from "@mui/material";
import { useLocation } from "react-router-dom";

interface ILogo {
  logoPath: string;
  sx?: SxProps;
  textOnly?: boolean;
}

export default function Logo({ sx = {}, logoPath, textOnly = false }: ILogo) {
  const location = useLocation();
  const isPaymentPage = location.pathname === "/payment";

  return (
    <Box
      href={"/"}
      component={Link}
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        fontSize: 20,
        color: "primary.main",
        fontWeight: "bold",
        transition: "transform 0.2s ease-in-out",
        transform: "scale(1)",
        textDecoration: "none",
        pointerEvents: isPaymentPage ? "none" : "auto",
        "&:hover": {
          transform: "scale(1.05)",
        },
        ...sx,
      }}
    >
      {!textOnly && (
        <img
          src={logoPath}
          alt={__APP_NAME__}
          style={{
            height: "auto",
            width: "32px",
          }}
        />
      )}
      {__APP_NAME__}
    </Box>
  );
}
