import useTranslation from "@cospex/client/hooks/useTranslation";
import { Section } from "@cospex/client/tracker/components/Layout";
import Pin from "@cospex/client/tracker/components/icons/Pin";
import AllType from "@cospex/client/tracker/components/sections/AllType";
import { Stack, Typography, useTheme } from "@mui/material";

export default function PhonesLocated() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Section
      sx={{
        py: {
          xs: 4,
          md: 12,
          lg: 14,
        },
        borderTop: `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      <Stack
        sx={{
          alignItems: "center",
          rowGap: "1rem",
          mb: 4,
          textAlign: "center",
        }}
      >
        <Pin color={theme.palette.primary.main} />
        <Typography color="primary.main" variant="h4">
          {t("home-phones-located-title")}
        </Typography>
        <Typography
          sx={{
            textDecoration: "capitalize",
          }}
        >
          {t("home-phones-located-desc", {
            site: __APP_DOMAIN__[0].toUpperCase() + __APP_DOMAIN__.slice(1),
          })}
        </Typography>
      </Stack>
      <AllType />
    </Section>
  );
}
