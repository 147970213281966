import PaymentLogos from "@cospex/client/components/PaymentLogos";
import usePrices from "@cospex/client/hooks/usePrices";
import { zodResolver } from "@hookform/resolvers/zod";
import { LockRounded } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  InputBaseComponentProps,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { InputMask } from "@react-input/mask";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import valid from "card-validator";
import { defer } from "lodash-es";
import { Ref, forwardRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { API_PATH_CHECK_PAYMENT, API_PATH_PAYMENT } from "../../constants";
import TextInput from "../../forms/TextInput";
import useAuth from "../../hooks/useAuth";
import usePaymentLibraries from "../../hooks/usePaymentLibraries";
import { useTitle } from "../../hooks/useTitle";
import useTranslation from "../../hooks/useTranslation";

type CCData = {
  cardHolder: string;
  cardNumber: string;
  cardExpire: string;
  cardSecurity: string;
};

declare global {
  interface Window {
    SecureTrading: any;
  }
}
declare global {
  interface Window {
    dataLayer: any;
  }
}

const CardNumberMaskFieldComponent = forwardRef(function CardNumberMaskField(
  otherProps: InputBaseComponentProps,
  inputRef: Ref<HTMLInputElement>
) {
  return (
    <InputMask
      {...otherProps}
      ref={inputRef}
      mask="____ ____ ____ ____"
      replacement={{ _: /\d/ }}
    />
  );
});

const CardSecurityMaskFieldComponent = forwardRef(
  function CardSecurityMaskField(
    otherProps: InputBaseComponentProps,
    inputRef: Ref<HTMLInputElement>
  ) {
    return (
      <InputMask
        {...otherProps}
        ref={inputRef}
        mask="___"
        replacement={{ _: /\d/ }}
      />
    );
  }
);

const CardExpireMaskFieldComponent = forwardRef(function CardExpireMaskField(
  otherProps: InputBaseComponentProps,
  inputRef: Ref<HTMLInputElement>
) {
  return (
    <InputMask
      {...otherProps}
      ref={inputRef}
      mask="__/__"
      replacement={{ _: /\d/ }}
    />
  );
});

const schema = z.object({
  cardHolder: z.string().refine((val) => valid.cardholderName(val).isValid, {
    message: "payment-cardHolder-invalid",
  }),
  cardNumber: z.string().min(19, "payment-cardNumber-invalid"),
  cardExpire: z
    .string()
    .refine((value) => valid.expirationDate(value).isValid, {
      message: "payment-cardExpire-invalid",
    }),
  cardSecurity: z
    .string()
    .max(3)
    .refine((value) => valid.cvv(value).isValid, {
      message: "payment-cardSecurity-invalid",
    }),
});

interface PaymentFormProps {
  onPaymentSuccess?: () => void;
  onPaymentSuccessRedirectPath?: string;
}

export default function PaymentForm({
  onPaymentSuccess,
  onPaymentSuccessRedirectPath,
}: PaymentFormProps) {
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsHighlighted, setTermsHighlighted] = useState(false);
  const [noDetailsError, setNoDetailsError] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [identifierParams, setIdentifierParams] = useSearchParams();
  const { signin } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const prices = usePrices();
  const { checkoutDeviceSession, secureTradingReady } = usePaymentLibraries();
  const identifier = identifierParams.get("identifier");
  const jwt = identifierParams.get("jwt");
  const alto = identifierParams.get("alto");
  const livestatus = identifierParams.get("livestatus");
  useTitle(t("payment-page-title"));

  const { handleSubmit, control, setValue, formState, getFieldState } =
    useForm<CCData>({
      resolver: zodResolver(schema),
      mode: "onChange",
      defaultValues: {
        cardHolder: "",
        cardNumber: "",
        cardExpire: "",
        cardSecurity: "",
      },
    });

  const query = useQuery({
    queryKey: ["paymentIdentifier"],
    queryFn: async () => {
      return await axios.get(`${API_PATH_CHECK_PAYMENT}/${identifier}`);
    },
    enabled: !!identifier && secureTradingReady,
    staleTime: Infinity,
    retry: false,
    onSuccess: (data) => {
      const token = data?.data?.token || "";
      const refreshToken = data?.data?.refresh_token || "";
      if (token) {
        if (import.meta.env.PROD && import.meta.env.MODE !== "staging") {
          window.dataLayer.push({
            event: "payment_successful",
            email: data?.data?.email,
          });
        }
        onPaymentSuccess?.();
        signin(data?.data?.email, token, refreshToken);
        navigate(onPaymentSuccessRedirectPath || "/dashboard", {
          replace: true,
        });
        return;
      }
      setErrMsg(data?.data?.error);
      setValue("cardHolder", data?.data?.cardHolder);
      setValue("cardNumber", data?.data?.cardNumber);
      setValue("cardExpire", data?.data?.cardExpiry);
      setValue("cardSecurity", data?.data?.cardSecurity);

      // payment cascading to TP
      if (jwt?.length) {
        setTermsChecked(true);
        setVendorLoading(true);
        defer(() => {
          const st = window.SecureTrading({
            jwt: jwt,
            livestatus: parseInt(livestatus!),
            submitOnError: true,
            submitCallback: (dataCallback: any) => {
              console.log(dataCallback.errorcode);
              setVendorLoading(false);
            },
          });
          st.Components({ startOnLoad: true });
        });
      } else if (alto?.length) {
        navigate("/3ds", { state: { alto: JSON.parse(alto) } });
      } else if (data.data.axepta) {
        navigate("/3ds", { state: { axepta: data.data.axepta } });
      }
    },
    onError: (error) => {
      console.log("error");
      identifierParams.delete("identifier");
      setIdentifierParams(identifierParams);
      if (error instanceof AxiosError) {
        setNoDetailsError(true);
      }
    },
  });

  const mutation = useMutation({
    mutationFn: (formData: CCData) => {
      const data: any = {
        formData,
      };

      if (checkoutDeviceSession) {
        data.formData.checkoutDeviceSession = checkoutDeviceSession;
      }

      return axios.post(API_PATH_PAYMENT, data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    onSuccess: (data) => {
      if (data.data.token) {
        if (import.meta.env.PROD && import.meta.env.MODE !== "staging") {
          window.dataLayer.push({
            event: "payment_successful",
            email: localStorage.getItem("onboardingEmail"),
          });
        }
        signin(
          localStorage.getItem("onboardingEmail")!,
          data.data.token,
          data.data.refresh_token
        );
        onPaymentSuccess?.();
        navigate(onPaymentSuccessRedirectPath || "/dashboard", {
          replace: true,
        });
      } else if (data.data.redirect) {
        window.location.replace(data.data.redirect);
      } else if (data.data.jwt) {
        setVendorLoading(true);
        const st = window.SecureTrading({
          jwt: data.data.jwt,
          livestatus: data.data.livestatus,
          submitOnError: true,
          submitCallback: (dataCallback: any) => {
            console.log(data.data.livestatus);
            console.log(dataCallback.errorcode);
            setVendorLoading(false);
          },
        });
        st.Components({ startOnLoad: true });
      } else if (data.data.alto) {
        navigate("/3ds", { state: { alto: data.data.alto } });
      } else if (data.data.axepta) {
        navigate("/3ds", { state: { axepta: data.data.axepta } });
      }
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        setErrMsg(error.response?.data.error);
      }
    },
  });

  useEffect(() => {
    axios.post("api/browser-infos", {
      screenWidth: screen.width,
      screenHeight: screen.height,
      timezone: new Date().getTimezoneOffset(),
      javaEnabled: navigator.javaEnabled(),
      colorDepth: screen.colorDepth,
      language: navigator.language,
    });

    setTimeout(() => {
      document.getElementById("cardHolder")?.focus();
    }, 0);

    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const onSubmit = (data: CCData) => {
    if (!termsChecked) {
      setTermsHighlighted(true);
      return;
    }
    data.cardNumber = data.cardNumber.replace(/ /g, "");
    mutation.mutate(data);
  };

  const DEV_autofillCardData = () => {
    const cardHolder = "midbypass";
    const cardNumber = "4111 1111 1111 1151";
    const cardExpire = "12/25";
    const cardSecurity = "123";
    setValue("cardHolder", cardHolder);
    setValue("cardNumber", cardNumber);
    setValue("cardExpire", cardExpire);
    setValue("cardSecurity", cardSecurity);
  };

  if (query.isFetching) {
    return (
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          gap={1.5}
          mb={5}
          justifyContent="center"
          sx={{
            fontSize: "1.1rem",
            mb: {
              md: 6,
              sm: 4,
              xs: 2,
            },
          }}
        >
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  if (noDetailsError) {
    return (
      <Container>
        <Alert severity="error">
          We were not able to find your session. Please go to the{" "}
          <Link href="/">home page</Link> and try tracking again.
        </Alert>
      </Container>
    );
  }

  const errorMessage = errMsg || (mutation.error as Error)?.message;
  const loading = vendorLoading || mutation.isLoading || query.isFetching;

  return (
    <>
      <Stack
        gap={1}
        sx={{
          py: 2,
          px: 4,
          backgroundColor: (theme) => `${theme.palette.primary.main}10`,
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              md: "20px",
              sm: "16px",
              xs: "14px",
            },
          }}
          mb={-0.5}
        >
          <LockRounded sx={{ mr: 0.5, position: "relative", top: 5 }} />
          {t("secure-payment")}
        </Typography>
        <Stack
          textAlign="right"
          p={1}
          borderRadius={2}
          flexDirection="row"
          alignItems="center"
        >
          <Typography variant="h4" fontWeight={400} mr={1}>
            {t("payment-price-total")}
          </Typography>
          <Typography variant="h3">{prices.localeSubscriptionPrice}</Typography>
        </Stack>
      </Stack>
      <Stack mb={3} alignItems="center">
        <PaymentLogos />
      </Stack>
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }} data-test="payment-error">
          <AlertTitle>{t("status-error")}</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <Box id="st-notification-frame"></Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        id="st-form"
        action="/api/valid-payment"
        method="POST"
      >
        <Box
          sx={{
            px: {
              md: 4,
              xs: 2,
            },
          }}
        >
          <Stack gap={2}>
            <TextInput
              control={control}
              name="cardHolder"
              label={t("payment-form-card-holder")}
              formState={formState}
              fullWidth
              inputProps={{ autoComplete: "cc-name" }}
            />
            <div data-private>
              <TextInput
                control={control}
                name="cardNumber"
                inputProps={{ inputMode: "numeric", autoComplete: "cc-number" }}
                label={t("payment-form-card-number")}
                formState={formState}
                inputComponent={CardNumberMaskFieldComponent}
                onChangeCb={() => {
                  defer(() => {
                    if (!getFieldState("cardNumber").invalid) {
                      document.getElementById("cardExpire")?.focus();
                    }
                  });
                }}
                fullWidth
              />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div data-private>
                  <TextInput
                    control={control}
                    name="cardExpire"
                    inputProps={{
                      inputMode: "numeric",
                      autoComplete: "cc-exp",
                    }}
                    label={t("payment-ed-label")}
                    formState={formState}
                    inputComponent={CardExpireMaskFieldComponent}
                    onChangeCb={() => {
                      defer(() => {
                        if (!getFieldState("cardExpire").invalid) {
                          document.getElementById("cardSecurity")?.focus();
                        }
                      });
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div data-private>
                  <TextInput
                    control={control}
                    name="cardSecurity"
                    inputProps={{
                      inputMode: "numeric",
                      autoComplete: "cc-csc",
                    }}
                    label="CVV/CVC"
                    formState={formState}
                    inputComponent={CardSecurityMaskFieldComponent}
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>
          </Stack>
          <Stack direction="row" mt={2}>
            <Box
              data-test="terms-label"
              component="label"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Checkbox
                sx={{
                  position: "relative",
                  top: -12,
                  color:
                    termsHighlighted && !termsChecked
                      ? "error.main"
                      : undefined,
                }}
                checked={termsChecked}
                onChange={(e) => setTermsChecked(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                mb={1}
                sx={{
                  color: "rgb(33, 38, 47)",
                  cursor: "pointer",
                  fontFamily:
                    "Montserrat, Arial, Tahoma, Verdana, Helvetica, sans-serif",
                  fontSize: "9.625px",
                  fontWeight: 400,
                  lineHeight: "13.4667px",
                  marginBottom: "5px",
                  position: "relative",
                  textAlign: "justify",
                }}
              >
                {t("terms-i-agree")}{" "}
                <Link
                  href="/terms"
                  target="_blank"
                  sx={{
                    color: "black",
                    fontSize: "9.625px",
                  }}
                >
                  {t("terms-terms-of-use")}
                </Link>{" "}
                {t("terms-and")}{" "}
                <Link
                  href="/privacy"
                  target="_blank"
                  sx={{
                    color: "black",
                    fontSize: "9.625px",
                  }}
                >
                  {t("terms-privacy-policy")}
                </Link>{" "}
                {t("terms-of-website")}
                {" " + __APP_DOMAIN__ + ". "}
                <Box
                  component="span"
                  sx={{
                    display: "inline",
                    textAlign: "left",
                    fontSize: 8,
                    lineHeight: "11.2px",
                  }}
                >
                  {t("terms-accept-addendum")}
                </Box>
              </Typography>
            </Box>
          </Stack>
          {termsHighlighted && !termsChecked && (
            <Box
              sx={{
                pl: 5,
              }}
            >
              <Typography variant="caption" color="error">
                {t("terms-please-accept")}
              </Typography>
            </Box>
          )}
        </Box>
        <Stack
          textAlign="right"
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          gap={1}
          mt={2}
          sx={{
            px: 4,
          }}
        >
          <Button
            data-test="pay-button"
            disabled={loading}
            startIcon={<LockRounded />}
            type="submit"
            variant="contained"
            disableElevation
            sx={{
              textTransform: "uppercase",
              fontSize: 16,
              fontWeight: {
                md: "normal",
                xs: "medium",
              },
              width: "100%",
              minWidth: 200,
              zIndex: 10,
              py: {
                xs: 2,
                md: 1.5,
              },
              position: {
                md: "relative",
                xs: "fixed",
              },
              bottom: 0,
              borderRadius: {
                md: 2,
                xs: 0,
              },
            }}
            color="error"
          >
            <span>{t("payment-button-pay")}</span>
          </Button>
          {loading && (
            <Stack
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
            >
              <CircularProgress size={24} />
            </Stack>
          )}
        </Stack>
      </Box>
      {import.meta.env.DEV && (
        <Button
          onClick={DEV_autofillCardData}
          variant="outlined"
          color="success"
          disableElevation
          sx={{ minWidth: 200 }}
        >
          Autofill
        </Button>
      )}
    </>
  );
}
