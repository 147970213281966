export default function SafetyShield({ color }: { color: string }) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99375 0.0897317C10.2246 -0.0128188 10.4833 -0.0274689 10.7221 0.0457815L10.8397 0.0897317L20.2147 4.25513C20.5917 4.42265 20.8307 4.79459 20.8334 5.19973L20.827 5.32209L20.2307 10.6884C19.926 13.4314 18.4291 15.895 16.1484 17.4301L15.8818 17.6031L10.9688 20.6737C10.6685 20.8614 10.2965 20.8822 9.98033 20.7363L9.86462 20.6737L4.95158 17.6031C2.61123 16.1404 1.03897 13.7241 0.643095 11.0036L0.602661 10.6884L0.00640596 5.32209C-0.0391574 4.91202 0.161416 4.51804 0.509873 4.31135L0.618749 4.25513L9.99375 0.0897317ZM10.4167 2.18152L2.16116 5.84953L2.67325 10.4583C2.90871 12.5775 4.059 14.4823 5.81287 15.6778L6.05574 15.8364L10.4167 18.562L14.7777 15.8364C16.5858 14.7064 17.8044 12.8446 18.1224 10.7459L18.1602 10.4583L18.6722 5.84953L10.4167 2.18152ZM12.7226 6.64687C13.078 6.19451 13.7329 6.11593 14.1852 6.47136C14.6028 6.79945 14.7019 7.38266 14.4351 7.82624L14.3607 7.934L10.1941 13.1423C9.83582 13.5983 9.18232 13.6684 8.73723 13.3225L8.63845 13.2353L6.55513 11.152C6.14834 10.7452 6.14834 10.0856 6.55513 9.67884C6.93063 9.30334 7.5215 9.27446 7.93013 9.59219L8.02827 9.67884L9.28134 10.9319L12.7226 6.64687Z"
        fill={color}
      />
    </svg>
  );
}
