interface IDeviceIcon {
  color: string;
}
export default function DeviceIcon({ color }: IDeviceIcon) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3316_739)">
        <rect
          x="20"
          y="15"
          width="80"
          height="80"
          rx="12"
          fill="white"
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip0_3316_739)">
          <path
            d="M40.3427 78.0409C39.4707 78.0409 38.6343 77.6945 38.0177 77.0779C37.4011 76.4613 37.0547 75.625 37.0547 74.7529V35.2489C37.0547 34.3769 37.4011 33.5406 38.0177 32.924C38.6343 32.3074 39.4707 31.9609 40.3427 31.9609H66.1187C66.9888 31.9672 67.8215 32.3157 68.4367 32.9309C69.052 33.5462 69.4004 34.3788 69.4067 35.2489V35.3689H80.0387C80.7947 35.3747 81.5192 35.6728 82.0605 36.2006C82.6018 36.7285 82.9179 37.4453 82.9427 38.2009V71.8009C82.9427 72.5734 82.6375 73.3145 82.0936 73.8629C81.5497 74.4113 80.8111 74.7226 80.0387 74.7289H69.4067V74.8489C69.4004 75.719 69.052 76.5517 68.4367 77.167C67.8215 77.7822 66.9888 78.1307 66.1187 78.1369L40.3427 78.0409ZM38.6627 74.7529C38.669 75.1943 38.8488 75.6155 39.1632 75.9254C39.4775 76.2353 39.9013 76.409 40.3427 76.4089H66.1187C66.5579 76.4089 66.9791 76.2345 67.2897 75.9239C67.6002 75.6133 67.7747 75.1921 67.7747 74.7529V74.6329H64.0307C63.2705 74.6334 62.5399 74.3381 61.9935 73.8096C61.4471 73.2811 61.1276 72.5607 61.1027 71.8009V69.9289H38.6627V74.7529ZM62.6627 71.7049C62.6627 72.0487 62.7992 72.3783 63.0423 72.6213C63.2853 72.8644 63.615 73.0009 63.9587 73.0009H79.9667C80.3104 73.0009 80.6401 72.8644 80.8831 72.6213C81.1261 72.3783 81.2627 72.0487 81.2627 71.7049V67.1209H62.7347L62.6627 71.7049ZM61.0307 68.2249V38.7529H38.6627V68.2249H61.0307ZM81.2627 65.4889V41.4889H62.7347V65.4889H81.2627ZM81.2627 39.7609V38.2009C81.2627 37.8572 81.1261 37.5276 80.8831 37.2845C80.6401 37.0415 80.3104 36.9049 79.9667 36.9049H64.0307C63.687 36.9049 63.3573 37.0415 63.1143 37.2845C62.8712 37.5276 62.7347 37.8572 62.7347 38.2009V39.6649L81.2627 39.7609ZM61.3667 37.1449C61.5878 36.6204 61.9582 36.1724 62.4318 35.8566C62.9055 35.5409 63.4615 35.3713 64.0307 35.3689H67.7747V35.2489C67.7747 34.8097 67.6002 34.3885 67.2897 34.078C66.9791 33.7674 66.5579 33.5929 66.1187 33.5929H40.3427C39.9013 33.5929 39.4775 33.7666 39.1632 34.0765C38.8488 34.3864 38.669 34.8075 38.6627 35.2489V37.1449H61.3667Z"
            fill={color}
          />
          <path
            d="M53.2326 75.807C52.72 75.807 52.2188 75.655 51.7926 75.3702C51.3663 75.0854 51.0341 74.6806 50.8379 74.207C50.6417 73.7333 50.5904 73.2122 50.6904 72.7094C50.7904 72.2066 51.0373 71.7447 51.3998 71.3822C51.7623 71.0197 52.2242 70.7729 52.727 70.6729C53.2298 70.5728 53.7509 70.6242 54.2245 70.8204C54.6982 71.0165 55.103 71.3488 55.3878 71.775C55.6726 72.2013 55.8246 72.7024 55.8246 73.215C55.8246 73.9025 55.5515 74.5618 55.0654 75.0479C54.5794 75.534 53.9201 75.807 53.2326 75.807ZM53.2326 72.255C53.036 72.2503 52.8426 72.3046 52.6771 72.4108C52.5117 72.5171 52.3819 72.6705 52.3044 72.8513C52.227 73.032 52.2055 73.2318 52.2427 73.4249C52.2799 73.618 52.3741 73.7955 52.5131 73.9346C52.6522 74.0736 52.8297 74.1678 53.0228 74.205C53.2158 74.2422 53.4157 74.2207 53.5964 74.1433C53.7771 74.0658 53.9306 73.936 54.0368 73.7706C54.1431 73.6051 54.1974 73.4116 54.1926 73.215C54.1926 72.9604 54.0915 72.7163 53.9114 72.5362C53.7314 72.3562 53.4872 72.255 53.2326 72.255Z"
            fill={color}
          />
          <path
            d="M71.9983 72.7358C71.4819 72.7311 70.9785 72.5736 70.5515 72.2833C70.1244 71.993 69.7929 71.5828 69.5986 71.1044C69.4042 70.626 69.3558 70.1007 69.4595 69.5949C69.5631 69.089 69.8141 68.6251 70.1809 68.2617C70.5477 67.8982 71.0139 67.6515 71.5207 67.5524C72.0274 67.4534 72.5522 67.5066 73.0288 67.7053C73.5054 67.904 73.9126 68.2393 74.199 68.6689C74.4854 69.0986 74.6382 69.6034 74.6383 70.1198C74.6415 70.4663 74.5753 70.8099 74.4435 71.1303C74.3117 71.4508 74.1169 71.7415 73.8708 71.9854C73.6247 72.2293 73.3322 72.4214 73.0105 72.5503C72.6889 72.6791 72.3447 72.7422 71.9983 72.7358ZM71.9983 69.1358C71.7392 69.1419 71.4924 69.2475 71.3092 69.4308C71.126 69.614 71.0203 69.8607 71.0143 70.1198C71.0143 70.3808 71.1179 70.6311 71.3025 70.8156C71.487 71.0001 71.7373 71.1038 71.9983 71.1038C72.2592 71.1038 72.5095 71.0001 72.694 70.8156C72.8786 70.6311 72.9823 70.3808 72.9823 70.1198C72.9823 69.8588 72.8786 69.6085 72.694 69.424C72.5095 69.2395 72.2592 69.1358 71.9983 69.1358Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3316_739"
          x="0"
          y="0"
          width="120"
          height="120"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3316_739"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3316_739"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3316_739">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(36 31)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
